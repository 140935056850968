import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getGenreOptionsState,
  getInvitedUserState,
  getSyncedInstaMatrixState,
} from "store/selectors";
import {
  requestGenreOptions,
  requestInstaMatrixDetails,
  requestSyncInstaMedia,
  requestUserProfile,
} from "store/rext";
import Loading from "components/Loading";
import HeaderLayout from "components/HeaderLayout";
import ProfileHeaderComponent from "components/ProfileHeaderComponent";
import ExpandCollapse from "components/ExpandCollapse";
import useInstaHandleHook from "HOC/useInstaHandle";
import { formatNumber } from "utils/utils";
import { URLRoutes } from "URLRoutes";
import { useUserDetail } from "HOC/useUserDetail";
import {
  InstagramView,
  FollowersByAge,
  FollowersByLocation,
  EngagementView,
} from "./Views";
import { IPROFILEVIEWS } from "utils/Enums";

import "./style.scss";

export default function DetailedProfile() {
  const { handle = "" } = useParams();
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const { instaHandle, userName, userId } = useUserDetail();
  const { data: userData, fetching: userFetching } =
    useSelector(getInvitedUserState);
  const { instaDetails, created_via, is_facebook_authenticated } =
    userData || {};
  const { data, fetching } = useSelector(getSyncedInstaMatrixState);
  const { data: genreOptions } = useSelector(getGenreOptionsState);
  const url = URLRoutes.clients.instaDashboardDetail.replace(
    ":handle",
    (handle as string) || instaHandle
  );
  // const { instaProfile, isLoading } = useInstaHandleHook(
  //   handle || instaHandle,
  //   true, data && data.reach
  // );
  const {
    followers_count,
    follows_count,
    media_count,
    media,
    profile_picture_url,
    avgComments,
    avgLikes,
    name,
    biography,
    reach,
  } = data || {};

  const handleToggle = useCallback((index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  }, []);

  const renderView = useCallback(
    (view: IPROFILEVIEWS, index: number) => {
      const isLastItem = index === Object.values(IPROFILEVIEWS).length - 1;
      const isActive = index === activeIndex;

      if (
        !(created_via === "facebook" || is_facebook_authenticated) &&
        view !== IPROFILEVIEWS.Content
      ) {
        return null;
      }

      return (
        <ExpandCollapse
          title={view}
          isActive={isActive}
          onToggle={() => handleToggle(index)}
          isLastItem={isLastItem}
        >
          {(() => {
            switch (view) {
              case IPROFILEVIEWS.Content:
                return (
                  <InstagramView
                    profilePictureUrl={profile_picture_url}
                    media={media}
                    // followers={
                    //   instaDetails?.followers_count
                    //     ? formatNumber(instaDetails.followers_count)
                    //     : "-"
                    // }
                    // avgLikes={
                    //   instaDetails?.avglikes
                    //     ? formatNumber(instaDetails.avglikes)
                    //     : "-"
                    // }
                    followers={
                      followers_count ? formatNumber(followers_count) : "-"
                    }
                    avgLikes={avgLikes ? formatNumber(avgLikes) : "-"}
                    avgComments={avgComments ? formatNumber(avgComments) : "-"}
                    // avgComments={
                    //   instaDetails?.avgcomments
                    //     ? formatNumber(instaDetails.avgcomments)
                    //     : "-"
                    // }
                    avgReelViews={instaDetails?.avgreelviews || "-"}
                    engageRatio={instaDetails?.engagementratio}
                    impressions={instaDetails?.impressions || "-"}
                    instaHandle={handle}
                    isLoadingPosts={fetching || false}
                    baseClassName=""
                    numberOfPosts={instaDetails?.totalposts || "-"}
                    hobScore={instaDetails?.hobscore || "-"}
                    showMetrices={
                      created_via === "facebook" || is_facebook_authenticated
                    }
                  />
                );
              case IPROFILEVIEWS.FollowersByAge:
                return <FollowersByAge instaHandle={handle} />;
              case IPROFILEVIEWS.Engagement:
                return <EngagementView instaHandle={handle} />;
              case IPROFILEVIEWS.FollowersByLocation:
                return <FollowersByLocation instaHandle={handle} />;
              default:
                return null;
            }
          })()}
        </ExpandCollapse>
      );
    },
    [activeIndex, data, userData, instaHandle, fetching]
  );

  useEffect(() => {
    dispatch(
      requestSyncInstaMedia(
        {},
        {
          instaHandle: handle || instaHandle,
        }
      )
    );
    dispatch(requestInstaMatrixDetails({ handle }, { instaHandle: handle }));

    dispatch(requestUserProfile({ id: userId }));
    dispatch(requestGenreOptions());
  }, [handle]);

  return (
    <HeaderLayout
      title="Detailed Profile Page"
      breadcrumbs={[
        {
          text: "Dashboard",
          url: URLRoutes.clients.instaDashboard,
        },
        {
          text: "Detailed Profile Page",
          url: url,
        },
      ]}
      contentClassName="flex flex-column flex-align-center width-100 padding-6 background-grey-shade5"
    >
      {!fetching && !userFetching && (
        <ProfileHeaderComponent
          posts={media_count ? formatNumber(media_count) : "-"}
          profilePictureUrl={profile_picture_url}
          profileName={userName || name}
          followers={followers_count ? formatNumber(followers_count) : "-"}
          following={follows_count ? formatNumber(follows_count) : "-"}
          accountReach={reach || "-"}
          city={userData.city || "-"}
          state={userData.state || "-"}
          handle={handle}
          genreOptions={genreOptions}
          genres={userData.gener || []}
          bio={biography}
        />
      )}
      {!fetching && !userFetching && (
        <div className="width-97 background-secondary-color margin-t-5 instagram-view">
          {Object.values(IPROFILEVIEWS).map(renderView)}
        </div>
      )}
      {(fetching || userFetching) && <Loading isGlobal />}
    </HeaderLayout>
  );
}
