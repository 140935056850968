import {
  AUTH_ACTION,
  AUTHENTICATE_WITH_FACEBOOK,
  CURRENT_USER_ACTION,
  LOGOUT_ACTION,
  SIGN_IN_WITH_FACEBOOK,
  SIGN_IN_WITH_GOOGLE,
  SIGN_UP_WITH_FACEBOOK,
  SIGN_UP_WITH_GOOGLE,
} from "store/actions";
import { StandardAction, DEFAULT_INITIAL_ACTION_STATE } from "../common";
import { Feature } from "utils/Features/Features";
import { ROLES } from "utils/Enums";

export interface IUser {
  email: string;
  role: ROLES;
}

export interface IAuthState {
  isAuthenticated: boolean;
  token: string;
  user: any;
  features: Feature;
  fetching: boolean;
  fetchingUser: boolean;
  message: string;
  error: boolean;
  emailVerified: boolean;
}

const INITIAL_STATE: IAuthState = {
  isAuthenticated: false,
  token: localStorage.getItem("token")!,
  user: undefined!,
  fetching: false,
  fetchingUser: false,
  message: undefined!,
  error: false,
  features: undefined!,
  emailVerified: false,
};

const authReducer = (
  state: IAuthState = INITIAL_STATE,
  action: StandardAction = DEFAULT_INITIAL_ACTION_STATE
): IAuthState => {
  const { type, payload } = action;

  // Handling AUTH_ACTION and CURRENT_USER_ACTION
  if (type === AUTH_ACTION.REQUEST) {
    return { ...INITIAL_STATE, fetching: true };
  } else if (type === CURRENT_USER_ACTION.REQUEST) {
    return { ...state, fetchingUser: true };
  } else if (type === AUTH_ACTION.SUCCESS) {
    const { features, ...restProps } = payload.user;
    return {
      ...state,
      fetching: false,
      fetchingUser: false,
      token: payload.token,
      features: new Feature(features || []),
      user: restProps,
      isAuthenticated: true,
      message: payload.message,
    };
  } else if (
    type === AUTH_ACTION.FAILURE ||
    type === CURRENT_USER_ACTION.FAILURE
  ) {
    return {
      ...state,
      fetching: false,
      fetchingUser: false,
      user: undefined!,
      error: true,
      token: undefined!,
      isAuthenticated: false,
      message: payload.message,
    };
  } else if (type === CURRENT_USER_ACTION.SUCCESS) {
    const { features, ...restProps } = payload.user;
    return {
      ...state,
      fetchingUser: false,
      user: restProps,
      features: new Feature(features || []),
      isAuthenticated: true,
    };
  }

  // Handling SIGN_IN_WITH_GOOGLE actions
  if (type === SIGN_IN_WITH_GOOGLE.REQUEST) {
    return { ...state, fetching: true };
  } else if (type === SIGN_IN_WITH_GOOGLE.SUCCESS) {
    const { features, ...restProps } = payload.user;
    return {
      ...state,
      fetching: false,
      fetchingUser: false,
      token: payload.token,
      features: new Feature(features || []),
      user: restProps,
      isAuthenticated: true,
      message: payload.message,
    };
  } else if (type === SIGN_IN_WITH_GOOGLE.FAILURE) {
    return {
      ...state,
      fetching: false,
      error: true,
      message: payload.error,
      isAuthenticated: false,
      user: undefined,
    };
  }

  // Handling SIGN_UP_WITH_GOOGLE actions
  if (type === SIGN_UP_WITH_GOOGLE.REQUEST) {
    return { ...state, fetching: true, error: false, message: undefined! };
  } else if (type === SIGN_UP_WITH_GOOGLE.SUCCESS) {
    return {
      ...state,
      fetching: false,
      token: payload.token,
      // emailVerified: true,
      user: payload.user,
      message: payload.message,
    };
  } else if (type === SIGN_UP_WITH_GOOGLE.FAILURE) {
    return {
      ...state,
      fetching: false,
      error: true,
      message: payload.error,
      isAuthenticated: false,
      user: undefined,
    };
  }

  // Handling SIGN_IN_WITH_FACEBOOK actions
  if (type === SIGN_IN_WITH_FACEBOOK.REQUEST) {
    return { ...state, fetching: true };
  } else if (type === SIGN_IN_WITH_FACEBOOK.SUCCESS) {
    const { features, ...restProps } = payload.user;
    return {
      ...state,
      fetching: false,
      fetchingUser: false,
      token: payload.token,
      features: new Feature(features || []),
      user: restProps,
      isAuthenticated: true,
    };
  } else if (type === SIGN_IN_WITH_FACEBOOK.FAILURE) {
    return {
      ...state,
      fetching: false,
      error: true,
      message: payload.error,
      isAuthenticated: false,
      user: undefined,
    };
  }

  if (type === AUTHENTICATE_WITH_FACEBOOK.REQUEST) {
    return { ...state, fetching: true, error: false, message: undefined! };
  } else if (type === AUTHENTICATE_WITH_FACEBOOK.SUCCESS) {
    return {
      ...state,
      fetching: false,
      token: payload.token,
      user: payload.user,
      emailVerified: payload.emailVerified,
      message: payload.message,
    };
  } else if (type === AUTHENTICATE_WITH_FACEBOOK.FAILURE) {
    return {
      ...state,
      fetching: false,
      error: true,
      message: payload.error,
      emailVerified: false,
      isAuthenticated: false,
      user: undefined,
    };
  }

  // Handling SIGN_UP_WITH_FACEBOOK actions
  if (type === SIGN_UP_WITH_FACEBOOK.REQUEST) {
    return { ...state, fetching: true, error: false, message: undefined! };
  } else if (type === SIGN_UP_WITH_FACEBOOK.SUCCESS) {
    return {
      ...state,
      fetching: false,
      token: payload.token,
      // emailVerified: true,
      user: payload.user,
      message: payload.message,
    };
  } else if (type === SIGN_UP_WITH_FACEBOOK.FAILURE) {
    return {
      ...state,
      fetching: false,
      error: true,
      message: payload.error,
      isAuthenticated: false,
      user: undefined,
    };
  }

  // Handling LOGOUT_ACTION
  if (type === LOGOUT_ACTION) {
    return { ...INITIAL_STATE };
  }

  // Default state return
  return state;
};

export default authReducer;
