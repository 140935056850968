import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFieldArray, useForm } from "react-hook-form";
import CustomButton from "components/CustomButton";
import {
  HookFormInput,
  HookFormRadio,
  HookFormSelect,
  HookFormTextarea,
  HookFormDatePicker,
  HookFormFileUploader,
  HookFormAddressAutoComplete,
} from "components/FormInputs";
import HeaderLayout from "components/HeaderLayout";
import {
  campaignFollowersOptions,
  campaignOptions,
  compaignTypeOptions,
  NewGenderOptions,
  paymentTermOptions,
  radioOptions,
  transformOptionsToKeyValue,
} from "utils/utils";
import {
  hookMinValue,
  hookPatternRequired,
  hookformRequired,
  validateDateRange,
  validateDateWithinRange,
} from "utils/FormValidations";
import { URLRoutes } from "URLRoutes";
import { useNavigate, useParams } from "react-router-dom";
import {
  requestBrandUsersList,
  requestBrandsList,
  requestCampaignDetail,
  requestCreateCampaign,
  requestGenreOptions,
  updateCampaign,
} from "store/rext";
import {
  getBrandListState,
  getBrandUsersListState,
  getCampaignDetailState,
  getGenreOptionsState,
} from "store/selectors";
import moment from "moment";
import { toast } from "react-toastify";
import UsePrevious from "HOC/UsePrevious";
import Loading from "components/Loading";
import { CampaignType, ROLES } from "utils/Enums";
import { useUserDetail } from "HOC/useUserDetail";
import classNames from "classnames";
import { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { ICON_POSITION } from "components/CustomButton/CustomButton";
import "./style.scss";

const requiredValidationwithMin: any = {
  ...hookformRequired("Required"),
  ...hookMinValue(5, "Must have 5 characters or above!"),
};
const requiredValidation: any = {
  ...hookformRequired("Required"),
};

const urlValidation = {
  ...hookPatternRequired(
    /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?(\?[^\s]*)?(#[^\s]*)?$/,
    "Invalid URL format"
  )
};

const DEFAULT_VALUE = {
  id: "",
  name: "",
  startdate: "",
  enddate: "",
  campaigntype: "",
  campaignvisibility: "",
  budget: null,
  paymentterm: "",
  contenturl: "",
  shortdescription: "",
  productdetails: "",
  hashtags: "",
  othercomments: "",
  golivedate: "",
  status: "",
  created_at: "",
  updated_at: "",
  visit_type: "",
  address: "",
  event_time: "",
  manageruserid: [],
  brandid: "",
  deliverables: [],
  gender: "",
  gener: "",
  category: "",
  image: undefined
};

function getDashboardUrl(role: string): string {
  return role === ROLES.HOB
    ? URLRoutes.clients.users.replace(":userType", ROLES.INFLUENCER)
    : role === ROLES.INFLUENCER
    ? URLRoutes.clients.instaDashboard
    : URLRoutes.clients.profile;
}

export default function CampaignForm() {
  const { id: campaignId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = useUserDetail();
  const updatedUrl = URLRoutes.clients.updateCampaign.replace(
    ":id",
    campaignId as string
  );
  const navigateToCampaignUrl = `${URLRoutes.clients.campaignList}?campaign_id=${campaignId}`;
  const {
    data: campaignData,
    fetching,
    message: campaignMessage,
    error,
  } = useSelector(getCampaignDetailState);
  const wasfecthing = UsePrevious(fetching);
  const { data: brandsList , fetching: brandsFetching } = useSelector(getBrandListState);
  const { data: brandUsersList , fetching: brandUserFetching } = useSelector(getBrandUsersListState);
  const { data: genreOptions } = useSelector(getGenreOptionsState);

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
    getValues
  } = useForm({
    defaultValues: useMemo(() => {
      const { id, updated_at, created_at, status, ...restProps } =
        campaignData || {};
      return campaignId ? restProps : DEFAULT_VALUE;
    }, [campaignId]),
  });

  const campaignType: CampaignType = watch("campaigntype");
  const visitType = watch("visit_type");
  const influencerCategory = watch("category");

  const defaultValue = campaignData.campaignManagers
        ? transformOptionsToKeyValue(campaignData.campaignManagers).map(({ value }) => value)
        : [];

    const validManagerIds = defaultValue.filter((managerId) =>
      brandUsersList?.records?.some((brandUser: any) => brandUser.id === managerId)
    );

  const { fields, append, remove } = useFieldArray({
    name: 'deliverables',
    control,
  });

  const handleAppend = () => {
    append({
      category: "",
      deliverable: "",
    });
  };

  const handleOnSubmit = (data: any) => {
    const {
      startdate,
      enddate,
      id,
      status,
      updated_at,
      created_at,
      campaignManagers,
      budget,
      manageruserid,
      managerUserId,
      golivedate,
      visit_type,
      address,
      event_time,
      guidelines,
      brandInfo,
      Influencers_count,
      ...rest
    } = data || {};
    const StartDate = startdate ? moment(startdate).format("YYYY-MM-DD") : "";
    const EndDate = enddate ? moment(data.enddate).format("YYYY-MM-DD") : "";
    const Budget = budget ? parseInt(budget , 10) : 0;
    const localTime = moment(event_time); 
    const formattedTime = localTime.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    const transformData = {
      budget: Budget,
      startdate: StartDate,
      enddate: EndDate,
      golivedate: golivedate ? moment(golivedate).format("YYYY-MM-DD") : "",
      manageruserid,
      ...rest,
    };
    console.log(data , transformData , "onSubmit")
    if (
      campaignType === CampaignType.PAID_UGC ||
      campaignType === CampaignType.BARTER_UGC
    ) {
      if (visitType === "LOCATION") {
        transformData.visit_type = visit_type;
        transformData.address = address;
      } else {
        transformData.visit_type = visit_type;
        transformData.address = address;
        transformData.event_time = event_time ? formattedTime : "";
      }
    }

    // Validation
    if (StartDate && EndDate && !validateDateRange(StartDate, EndDate)) {
      toast.error("Start date must be before end date");
      return;
    }

    if (startdate && enddate && golivedate && !validateDateWithinRange(startdate, enddate, golivedate)) {
      toast.error(
        "Go Live Date must be between Start Date and End Date or the same as End Date."
      );
      return;
    }

    // Validate event_time
    if (
      event_time && enddate && startdate && 
      !validateDateWithinRange(startdate, enddate, event_time)
    ) {
      toast.error(
        "Event Time must be between Start Date and End Date or the same as End Date."
      );
      return;
    }
    const formData = new FormData();
    Object.keys(transformData).forEach((key) => {
      if (Array.isArray(transformData[key]) && typeof transformData[key] === "object"
      ) {
        formData.append(key, JSON.stringify(transformData[key]));
      } else {
        formData.append(key, transformData[key] );
      }
    });
    // Submit API Call
    if (campaignId) {
      dispatch(updateCampaign(formData, { id: campaignId }));
    } else {
      dispatch(requestCreateCampaign(formData));
    }
    setTimeout(() => {
      if (campaignId) {
        navigate(navigateToCampaignUrl);
      } else {
        navigate(URLRoutes.clients.campaignList);
      }
    }, 3000);
  };

  const handleBrandNameChange = (value: any) => {
    if (value) {
      dispatch(
        requestBrandUsersList({
          brand_id: value,
          page: 1,
          pageSize: 10,
        })
      );
    }
  };

  const handleNavigate = () => {
    if (campaignId) {
      navigate(navigateToCampaignUrl);
    } else {
      // reset(DEFAULT_VALUE);
      navigate(URLRoutes.clients.campaignList)
    }
  };

  const renderFields = (field: any, index: number) => {
    return (
      <div
        key={field.id}
        className="flex flex-row  flex-align-center width-100 deliverable-rows"
      >
        <div className="width-100 flex flex-row flex-justify-between  flex-align-center">
          <HookFormSelect
            control={control}
            errors={errors}
            id={`deliverables[${index}].category`}
            name={`deliverables[${index}].category`}
            placeholder="Select"
            baseClassName="width-49"
            options={campaignFollowersOptions}
            isDisabled
          />
          <HookFormInput
            control={control}
            errors={errors}
            id={`deliverables[${index}].deliverable`}
            name={`deliverables[${index}].deliverable`}
            placeholder="Enter text"
            baseClassName="width-49"
            validation={requiredValidation}
            required
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (campaignId && Object.keys(campaignData).length > 0) {
      const { id, updated_at, created_at, status, ...restProps } = campaignData || {};
      reset(restProps);
      setValue("manageruserid", validManagerIds);
      setValue("budget", restProps.budget?.toString());
      restProps.brandid &&
        dispatch(
          requestBrandUsersList({
            brand_id: restProps.brandid,
            page: 1,
            pageSize: 10,
          })
        );
    }
  }, [campaignId, reset, brandsList, campaignData]);


  useEffect(() => {
    if (campaignId) {
      dispatch(
        requestCampaignDetail(
          { id: campaignId },
          role === ROLES.INFLUENCER
            ? URLRoutes.server.campaignInfluencerDetail
            : URLRoutes.server.campaignDetail
        )
      );
    } else {
      reset(DEFAULT_VALUE);
    }
  }, [campaignId]);

  useEffect(() => {
    if (wasfecthing && !fetching && !error) {
      toast.success(campaignMessage);
    } else {
      toast.error(campaignMessage);
    }
  }, [fetching, error, campaignMessage]);

  useEffect(() => {
    if (influencerCategory) {
      const existingCategories = fields.map((field: any) => field.category);
      
      const newCategories = influencerCategory.filter(
        (cat: string) => !existingCategories.includes(cat)
      );
      
      const categoriesToRemove = fields.filter(
        (field: any) => !influencerCategory.includes(field.category)
      );
      
      // Add new categories
      newCategories.forEach((category: string) => {
        append({ category, deliverable: "" });
      });
      
      // Remove categories not in the influencerCategory
      categoriesToRemove.forEach((field:any) => {
        const index = fields.findIndex(
          (item: any) => item.category === field.category
        );
        if (index !== -1) {
          remove(index);
        }
      });
    }
  }, [influencerCategory, fields, append, remove]);

  useEffect(() => {
    dispatch(requestBrandsList({ page: 1, pageSize: 50 }));
    dispatch(requestGenreOptions());
  }, []);

  return (
    <HeaderLayout
      title={campaignId ? "Update Campaign" : "Create Campaign"}
      breadcrumbs={[
        {
          text: "Dashboard",
          url: getDashboardUrl(role)
        },
        {
          text: "Campaigns" , 
          url: campaignId ?  navigateToCampaignUrl : URLRoutes.clients.campaignList
        },
        {
          text: campaignId ? "Update Campaign" : "Create Campaign",
          url: campaignId ? updatedUrl : URLRoutes.clients.createCampaign,
        },
      ]}
      contentClassName="overflow-auto"
    >
      <div className="text-4 text-grey-shade2 padding-8">
        Kindly complete the form below with the necessary details to initiate
        the creation of your campaign.
      </div>

      {!fetching && (
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          className="width-75 padding-l-8 "
        >
          <div className="flex flex-justify-between width-100">
            <HookFormSelect
              control={control}
              errors={errors}
              label="Brand Name"
              id="brandid"
              name="brandid"
              placeholder="Enter Brand Name"
              baseClassName="width-49"
              validation={requiredValidation}
              required
              options={transformOptionsToKeyValue(brandsList?.records)}
              triggerOnChange={(value: any) => handleBrandNameChange(value)}
              isDisabled={brandsFetching}
            />
            <HookFormSelect
              control={control}
              errors={errors}
              id="manageruserid"
              name="manageruserid"
              label="Brand Managers"
              options={transformOptionsToKeyValue(brandUsersList?.records)}
              placeholder={"Select"}
              baseClassName="width-49 campaign-managers"
              multiSelect
              loading={brandUserFetching || brandsFetching}
              // validation={requiredValidation}
            />
          </div>
          <div className="flex flex-justify-between width-100">
            <HookFormInput
              control={control}
              errors={errors}
              label="Campaign Name"
              id="name"
              name="name"
              placeholder="Enter Name"
              baseClassName="width-49"
              validation={requiredValidationwithMin}
              required
            />
            <HookFormInput
              control={control}
              errors={errors}
              label="Campaign Hashtag"
              id="hashtags"
              name="hashtags"
              placeholder="Enter Hashtag"
              baseClassName="width-49"
            />
          </div>
          <div className="flex flex-justify-between width-100">
            <div className="flex flex-justify-between width-49">
              <HookFormDatePicker
                control={control}
                errors={errors}
                id="startdate"
                name="startdate"
                label="Proposal Deadline"
                baseClassName="width-49"
                placeholder={"Enter Date"}
                minDate={new Date()}
                validation={requiredValidation}
                required
              />
              <HookFormDatePicker
                control={control}
                errors={errors}
                id="golivedate"
                name="golivedate"
                label="Go Live"
                baseClassName="width-49"
                placeholder={"Enter Date"}
                minDate={new Date()}
              />
            </div>
            <div className="flex flex-justify-between width-49">
              <HookFormSelect
                control={control}
                errors={errors}
                id="campaignType"
                name="campaigntype"
                label="Campaign Type"
                options={campaignOptions}
                placeholder={"Select"}
                baseClassName="width-49"
                validation={requiredValidation}
                required
              />
              <HookFormSelect
                control={control}
                errors={errors}
                id="campaignVisibility"
                name="campaignvisibility"
                label="Campaign Visibility"
                options={compaignTypeOptions}
                placeholder={"Select"}
                baseClassName="width-49"
              />
            </div>
          </div>

          <div className="flex flex-justify-between width-100">
            <HookFormDatePicker
              control={control}
              errors={errors}
              id="enddate"
              name="enddate"
              label="Campaign End Date"
              baseClassName="width-49"
              placeholder={"Enter Date"}
              minDate={new Date()}
              validation={requiredValidation}
              required
            />
            {(campaignType === CampaignType.BARTER_REPOST ||
              campaignType === CampaignType.PAID_REPOST) && (
              <HookFormInput
                control={control}
                errors={errors}
                label="Content"
                id="contenturl"
                name="contenturl"
                placeholder="Enter Content"
                baseClassName="width-49"
                validation={urlValidation}
              />
            )}
          </div>
          <div className="flex flex-justify-between width-100">
            <div className="flex flex-justify-between width-49">
              <HookFormSelect
                control={control}
                errors={errors}
                id="gender"
                name="gender"
                label="Gender"
                baseClassName="width-100"
                options={NewGenderOptions}
                inputClassName="width-100"
                placeholder={"Select"}
              />
            </div>
            <div className="flex flex-justify-between width-49">
              <HookFormInput
                control={control}
                errors={errors}
                label="Budget"
                id="budget"
                name="budget"
                placeholder="Enter Budget"
                baseClassName="width-49"
              />
              <HookFormSelect
                control={control}
                errors={errors}
                label="Payment Term (in days)"
                id="paymentterm"
                name="paymentterm"
                placeholder="Enter Payment Term"
                baseClassName="width-49"
                options={paymentTermOptions}
              />
            </div>
          </div>

          {(campaignType === CampaignType.BARTER_UGC ||
            campaignType === CampaignType.PAID_UGC) && (
            <div
              className={classNames(["flex flex-justify-between width-100"], {
                "margin-b-2": visitType !== "EVENT",
              })}
            >
              <HookFormRadio
                control={control}
                errors={errors}
                name="visit_type"
                label="Visit Place"
                options={radioOptions}
                id="visit_type"
                baseClassName="width-49"
                // inputClassName="width-50"
              />
              {visitType === "EVENT" && (
                <HookFormDatePicker
                  control={control}
                  errors={errors}
                  name="event_time"
                  id="event_time"
                  label="Event Time"
                  dateTimePicker
                  placeholder="Select Date and Time"
                  baseClassName="width-49"
                  minDate={new Date()}
                />
              )}
            </div>
          )}
          {(campaignType === CampaignType.BARTER_UGC ||
            campaignType === CampaignType.PAID_UGC) && (
            <HookFormAddressAutoComplete
              control={control}
              errors={errors}
              label="Confirm Address"
              id="address"
              name="address"
              placeholder="Enter Content"
              baseClassName="width-49"
              handleChange={(place: any) => setValue("address" , place )}
              onSelect={()=> {}}
            />
          )}
          <div className="flex flex-justify-between width-100">
            <HookFormSelect
              options={transformOptionsToKeyValue(genreOptions)}
              control={control}
              errors={errors}
              label="Genre"
              id="gener"
              name="gener"
              multiSelect
              placeholder="Select the genre(s)..."
              baseClassName="width-49 campaign-multiselect"
            />
            <HookFormSelect
              control={control}
              errors={errors}
              id="category"
              name="category"
              label="Influencer Category"
              baseClassName="width-49"
              options={campaignFollowersOptions}
              inputClassName="width-100"
              placeholder={"Select Category"}
              multiSelect
            />
          </div>
          <HookFormTextarea
            control={control}
            errors={errors}
            label="Campaign Brief"
            id="shortdescription"
            name="shortdescription"
            placeholder="Enter Description"
            baseClassName="width-100"
          />
          <div className="flex flex-row flex-justify-between width-100">
            <HookFormTextarea
              control={control}
              errors={errors}
              label="Product / Service Details"
              id="productdetails"
              name="productdetails"
              placeholder="Enter Detail"
              baseClassName="width-75"
            />
            <HookFormFileUploader
              control={control}
              errors={errors}
              name="image"
              acceptFileType=".jpg, .jpeg , .png , .gif"
              baseClassName="width-24 product-image"
              placeholder={`Drag & Drop or <span class="text-blue-shade-6">Browse</span> your Product File (e.g, PNG , JPG , JPEG).`}
              defaultValue={campaignId ? campaignData.productfile : null}
            />
          </div>
          <label className="input-label padding-l-1 padding-b-1"> Deliverables </label>
          {/* <CustomButton
            type="button"
            transparent
            greyButton
            iconProps={{
              name: CUSTOM_SVG_ICON.Plus,
              svgType: SVGType.CUSTOM,
              size: "small",
              baseclassname: "text-default-color",
            }}
            outline
            circular
            iconPosition={ICON_POSITION.LEFT}
            handleClick={handleAppend}
            disabled={!influencerCategory?.length}
          /> */}
          <div className="deliverable-rows"></div>
          {fields.map(renderFields)}
          <HookFormTextarea
            control={control}
            errors={errors}
            label="Additional Info"
            id="othercomments"
            name="othercomments"
            placeholder="Enter Comments"
            baseClassName="width-100 margin-t-2"
          />
          <div className="padding-t-4 padding-b-6">
            <CustomButton
              buttonText="Cancel"
              secondaryButton
              handleClick={handleNavigate}
              baseclassname={"cursor-pointer margin-r-4"}
              textSmall
              circular
            />
            <CustomButton
              type="submit"
              buttonText={campaignId ? "Update" : "Save"}
              primaryButton
              baseclassname={" cursor-pointer"}
              textSmall
              circular
            />
          </div>
        </form>
      )}
      {fetching && <Loading />}
    </HeaderLayout>
  );
}
