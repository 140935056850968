import { SagaIterator } from "redux-saga";
import { put, call, select, takeLatest } from "redux-saga/effects";
import {
  AUTH_ACTION,
  AUTHENTICATE_WITH_FACEBOOK,
  CURRENT_USER_ACTION,
  SIGN_IN_WITH_FACEBOOK,
  SIGN_IN_WITH_GOOGLE,
  SIGN_UP_WITH_FACEBOOK,
  SIGN_UP_WITH_GOOGLE,
} from "../actions";
import { fetchRequest, getFullUrl } from "Api";
import { getBaseUrl, getToken } from "store/selectors";
import { StandardAction } from "store/common";
import { URLRoutes } from "URLRoutes";

function* performRequestLogin(action: StandardAction): IterableIterator<{}> {
  const { payload } = action;

  try {
    const token: string = (yield select(getToken))!;
    const baseUrl: string = (yield select(getBaseUrl))!;
    const response: any = yield call(
      fetchRequest,
      `${baseUrl}${URLRoutes.server.login}`,
      token,
      "post",
      payload
    );
    // const userToken = `Bearer ${response.data.token}`
    localStorage.setItem("token", response.data.token);

    const responseUser: any = yield call(
      fetchRequest,
      `${baseUrl}${URLRoutes.server.currentUser}`,
      response.data.token,
      "get"
    );

    yield put({
      type: AUTH_ACTION.SUCCESS,
      payload: { user: responseUser.data, token: response.data.token , message: response.message },
    });
  } catch (error: any) {
    const message = error.message || error.errorText || error;
    yield put({ type: AUTH_ACTION.FAILURE, payload: {message: message} });
  }
}

function* performCurrentUserDetail(
  action: StandardAction
): IterableIterator<{}> {
  try {
    const token: string = (yield select(getToken))!;
    const baseUrl: string = (yield select(getBaseUrl))!;
    const response: any = yield call(
      fetchRequest,
      `${baseUrl}${URLRoutes.server.currentUser}`,
      token,
      "get"
    );
    yield put({
      type: CURRENT_USER_ACTION.SUCCESS,
      payload: { user: response.data },
    });
  } catch (error: any) {
    const message = error.message || error.errorText || error;
    yield put({ type: CURRENT_USER_ACTION.FAILURE, payload: message });
  }
}

function* signInWithGoogleSaga(action: StandardAction): IterableIterator<{}> {
  try {
    const { payload: token } = action;
    const baseUrl: string = (yield select(getBaseUrl))!;
    const signInUrl: string = getFullUrl(
      baseUrl,
      URLRoutes.server.googleLogin,
      { urlParams: { token } }
    );
    const response: any = yield call(
      fetchRequest,
      signInUrl,
      undefined!,
      "post"
    );
    localStorage.setItem("token", response.data.auth_token);
    const responseUser: any = yield call(
      fetchRequest,
      `${baseUrl}${URLRoutes.server.currentUser}`,
      response.data.auth_token,
      "get"
    );
    yield put({
      type: SIGN_IN_WITH_GOOGLE.SUCCESS,
      payload: { user: responseUser.data, token: response.data.auth_token , message: response.message },
    });
  } catch (error: any) {
    const message = error.message || error.errorText || error;
    yield put({ type: SIGN_IN_WITH_GOOGLE.FAILURE, payload: {error: message} });
  }
}

function* signUpWithGoogleSaga(action: StandardAction): IterableIterator<{}> {
  const { token, id } = action.payload;
  const baseUrl: string = (yield select(getBaseUrl))!;

  try {
    let body: any;

    if (id) {
      body = { inviteId: id };
    }
    const signupwithurl: string = getFullUrl(
      baseUrl,
      URLRoutes.server.registerByGoogle,
      { urlParams: { token: token } }
    );
    const response: any = yield call(
      fetchRequest,
      signupwithurl,
      undefined!,
      "post",
      body);
    yield put({
      type: SIGN_UP_WITH_GOOGLE.SUCCESS,
      payload: { user: { id: response.data } ,  message: response.message },
    });
  } catch (error: any) {
    const message = error.message || error.errorText || error;
    yield put({ type: SIGN_UP_WITH_GOOGLE.FAILURE, payload: { error: message } });
  }
}

function* signInWithFacebookSaga(action: StandardAction): IterableIterator<{}> {
  try {
    const { payload: token } = action;
    const baseUrl: string = (yield select(getBaseUrl))!;
    const signInUrl: string = getFullUrl(
      baseUrl,
      URLRoutes.server.facebookLogin,
      { urlParams: { token } }
    );
    const response: any = yield call(
      fetchRequest,
      signInUrl,
      undefined!,
      "post"
    );
    localStorage.setItem("token", response.data.auth_token);
    const responseUser: any = yield call(
      fetchRequest,
      `${baseUrl}${URLRoutes.server.currentUser}`,
      response.data.auth_token,
      "get"
    );
    yield put({
      type: SIGN_IN_WITH_FACEBOOK.SUCCESS,
      payload: { user: responseUser.data, token: response.data.auth_token },
    });
  } catch (error: any) {
    yield put({ type: SIGN_IN_WITH_FACEBOOK.FAILURE, payload: { error: error } });
  }
}

// Facebook Sign-Up Saga
function* signUpWithFacebookSaga(action: StandardAction): IterableIterator<{}> {
  const { token, id } = action.payload;
  const baseUrl: string = (yield select(getBaseUrl))!;

  try {
    let body: any;

    if (id) {
      body = { inviteId: id };
    }
    const signupwithurl: string = getFullUrl(
      baseUrl,
      URLRoutes.server.facebookSignup,
      { urlParams: { token: token } }
    );
    const response: any = yield call(
      fetchRequest,
      signupwithurl,
      undefined!,
      "post",
      body
    );
    yield put({
      type: SIGN_UP_WITH_FACEBOOK.SUCCESS,
      payload: { user: { id: response.data } , message: response.message },
    });
  } catch (error: any) {
    yield put({ type: SIGN_UP_WITH_FACEBOOK.FAILURE, payload: { error: error , message: error.message } });
  }
}

function* authenticateWithFacebookSaga(action: StandardAction) : IterableIterator<{}> {
  const { token, inviteId } = action.payload;
  const baseUrl: string = (yield select(getBaseUrl))!;

  try {
    const signupwithurl: string = getFullUrl(
      baseUrl,
      URLRoutes.server.facebookAuthentication,
      { urlParams: { token: token } }
    );
    const response: any = yield call(
      fetchRequest,
      signupwithurl,
      undefined!,
      "post",
      {inviteId: inviteId}
    );
    yield put({
      type: AUTHENTICATE_WITH_FACEBOOK.SUCCESS,
      payload: { user: { id: response.data } , message: response.message , emailVerified: response.accounts_found},
    });
  } catch (error: any) {
    yield put({ type: AUTHENTICATE_WITH_FACEBOOK.FAILURE, payload: { error: error , message: error.message } });
  }
}

export function* watchAuthEvent(): SagaIterator {
  yield takeLatest(AUTH_ACTION.REQUEST, performRequestLogin);
  yield takeLatest(CURRENT_USER_ACTION.REQUEST, performCurrentUserDetail);
  yield takeLatest(SIGN_IN_WITH_GOOGLE.REQUEST, signInWithGoogleSaga);
  yield takeLatest(SIGN_UP_WITH_GOOGLE.REQUEST, signUpWithGoogleSaga);
  yield takeLatest(SIGN_IN_WITH_FACEBOOK.REQUEST, signInWithFacebookSaga);
  yield takeLatest(SIGN_UP_WITH_FACEBOOK.REQUEST, signUpWithFacebookSaga);
  yield takeLatest(AUTHENTICATE_WITH_FACEBOOK.REQUEST, authenticateWithFacebookSaga);
}
