import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Dropdown, Popup } from "semantic-ui-react";
import CustomButton from "components/CustomButton";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import {
  getAllInfluencersListState,
  getFavouriteListState,
} from "store/selectors";
import {
  postInfluencersToCampaign,
  requestAllInfluencers,
  requestFavouritesList,
  requestGenreOptions,
} from "store/rext";
import { getGenreOptionsState } from "store/selectors";
import {
  GenderOptions,
  NewGenderOptions,
  findLabelById,
  followersOptions,
  formatNumber,
} from "utils/utils";
import Loading from "components/Loading";
import { CustomInput, FollowersDropdown } from "components/CustomInputs";
import { modalOperation } from "store/actions";
import { ModalsType } from "containers/ModalManager/ModalManager";
import { URLRoutes } from "URLRoutes";
import HeaderLayout from "components/HeaderLayout";
import CustomTable from "components/CustomTable";
import { IColumns } from "components/CustomTable/CustomTable";
import FavouritesOverlay from "components/FavouritesOverlay";
import TooltipText from "components/TooltipText";
import "./style.scss";

interface IFilters {
  location?: string;
  gender?: string;
  // geners?: string;
  search?: string;
  favouriteId?: string;
  isRegistered?: boolean;
  followers?: string;
  minFollowers?: number;
  maxFollowers?: number;
}

function InfluencerList() {
  const pageSize = 50;
  const dispatch = useDispatch();
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  // const [users, setUsers] = useState<Array<any>>([]);
  const [selectedAlphabet, setSelectedAlphabet] = useState<string>("ALL");
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [filters, setFilters] = useState<IFilters>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const {
    data,
    fetching,
    message: influencerMessage,
    error,
  } = useSelector(getAllInfluencersListState);
  const { records, paginationInfo } = data || {};
  const { data: genreOptions } = useSelector(getGenreOptionsState);
  const { data: favouriteData } = useSelector(getFavouriteListState);

  const columns: IColumns[] = [
    {
      key: "checkbox",
      header: "",
      columnClassName: "width-5",
      customValue: (data: any) => (
        <div className="flex flex-row flex-align-center flex-justify-center">
          <input
            name="userId"
            type="checkbox"
            checked={selectedUserIds.includes(data.id)}
            onChange={() => handleCheckboxChange(data.id)}
          />
        </div>
      ),
    },
    {
      key: "name",
      header: "Instagram Profiles",
      sort: true,
      sortKey: "name",
      columnClassName: "width-18",
      customValue: (data: any) => (
        <div className="flex flex-row flex-align-center width-100">
          <div className="influencer-detail-img text-2">
            <img
              src={data.profile_picture_url || "/images/NoProfile.png"}
              alt="Profile"
              onError={handleImageError}
            />
          </div>
          <div
            className="flex flex-column flex-justify-center  cursor-pointer"
            onClick={() =>
              handleInfluencerDetail(data.instahandle, data.userid)
            }
          >
            <div className="influencer-detail-text1 flex">
              {data.name}
              {data.profilestatus && data.profilestatus === "COMPLETED" && (
                <SvgIcon
                  name={CUSTOM_SVG_ICON.VerifiedTick}
                  svgType={SVGType.CUSTOM}
                  size={"small"}
                  baseclassname={"text-orange-shade1 margin-l-2"}
                />
              )}
            </div>
            <div className="influencer-detail-text2 flex margin-t-1">
              {data.instahandle}
            </div>
          </div>
        </div>
      ),
    },
    // {
    //   key: "hobscore",
    //   header: "Score",
    //   sortKey: "hobscore",
    //   customValue: (data: any) => "-",
    // },
    {
      key: "followers_count",
      header: "Followers",
      sortKey: "followers_count",
      columnClassName: "width-13",
      customValue: (data: any) =>
        data.followers_count ? formatNumber(data.followers_count) : "-",
    },
    {
      key: "avglikes",
      header: "Avg. Likes",
      sortKey: "avglikes",
      columnClassName: "width-13",
      customValue: (data: any) =>
        data.avglikes ? formatNumber(data.avglikes) : "-",
    },
    {
      key: "avgreelviews",
      header: "Avg. Reel Views",
      sortKey: "avgreelviews",
      columnClassName: "width-13",
      customValue: (data: any) =>
        data.avgreelviews ? formatNumber(data.avgreelviews) : "-",
    },
    {
      key: "engagementratio",
      header: "ER",
      sortKey: "engagementratio",
      columnClassName: "width-13",
      customValue: (data: any) =>
        data.engagementratio ? `${formatNumber(data.engagementratio)}%` : "-",
    },
    {
      key: "state",
      header: "Location",
      sortKey: "state",
      columnClassName: "width-13 influencer-location",
      customValue: (data: any) => <TooltipText value={data.state || "-"} />,
    },
    {
      key: "gener",
      header: "Categories",
      sortKey: "gener",
      columnClassName: "width-16 discovery-geners",
      customValue: (data: any) => renderGenres(data.gener) || "-",
    },
  ];

  const renderGenres = (genres: any) => {
    if (!genres || genres.length === 0) return null;

    const visibleGenres = genres.slice(0, 2);
    const hiddenGenres = genres.slice(2);

    return (
      <div className="flex flex-wrap">
        {visibleGenres.map((genre: string, index: number) => (
          <div
            key={`genre${index}`}
            className="flex flex-align-center text-align-center text-grey-shade-4 border-1 border-grey-shade-1 margin-1 influencer-genres"
          >
            {findLabelById(genreOptions, genre)}
          </div>
        ))}
        {hiddenGenres.length > 0 && (
          <Popup
            content={
              <div className="width-95 flex flex-column flex-justify-center">
                {hiddenGenres.map((genre: string, index: number) => (
                  <div
                    key={`genre${index}`}
                    className="flex flex-align-center flex-justify-center text-3  text-grey-shade-4 border-1 border-grey-shade-1 margin-1 profile-genres profile-genres-hidden text-align-center width-90"
                  >
                    {findLabelById(genreOptions, genre)}
                  </div>
                ))}
              </div>
            }
            trigger={
              <div className=" text-grey-shade-4 border-1 border-grey-shade-1  margin-1 influencer-genres">
                +{hiddenGenres.length}
              </div>
            }
            position="bottom center"
          />
        )}
      </div>
    );
  };

  const fetchInfluencers = (filterParams: IFilters = {} , alphabet?: string , page?: number) => {
    const filteredParams = Object.entries(filterParams).reduce((acc: any, [key, value]) => {
      if (value !== undefined && value !== "") {
        acc[key] = value;
      }
      return acc;
    }, {});

    const params: any = {
      page: page ? page : currentPage,
      pageSize: 100,
      sortByAlphabet: alphabet ? alphabet : selectedAlphabet,
     ...filteredParams
    };

    dispatch(requestAllInfluencers(params));
  };

  const handleImageError = (e: any) => {
    e.target.onerror = null;
    e.target.src = "/images/NoProfile.png";
  };

  const renderAlphabets = () => {
    const alphabets = [
      "ALL",
      ...[...new Array(26)].map((_, i) => String.fromCharCode(65 + i)),
    ];
    return alphabets.map((alphabet, index) => (
      <span
        className={classNames([
          `cursor-pointer padding-1`,
          { "active-alphabet": alphabet === selectedAlphabet },
        ])}
        key={index}
        onClick={() => handleAlphabetClick(alphabet)}
      >
        {alphabet}
      </span>
    ));
  };


  const handleAlphabetClick = (alphabet: string) => {
    setSelectedAlphabet(alphabet);
    setCurrentPage(1);
    const filteredParams = Object.entries(filters).reduce((acc: any, [key, value]) => {
      if (value !== undefined && value !== "") {
        acc[key] = value;
      }
      return acc;
    }, {});

    const params: any = {
      page: 1,
      pageSize: 100,
      sortByAlphabet: alphabet ,
     ...filteredParams
    };

     dispatch(requestAllInfluencers(params));
  };

  const handleFilterChange = (name: string, value: any) => {
    setCurrentPage(1);
    setSelectedAlphabet("ALL");
    setFilters((prevFilters) => {
      const updatedFilters: any = {
        ...prevFilters,
        [name]: value,
      };
      fetchInfluencers(updatedFilters , "ALL");
      return updatedFilters;
    });
  };

  const handleCheckboxChange = (id: string) => {
    const isSelected = selectedUserIds.includes(id);
    if (isSelected) {
      setSelectedUserIds((prevIds) =>
        prevIds.filter((userId) => userId !== id)
      );
    } else {
      setSelectedUserIds((prevIds) => [...prevIds, id]);
    }
  };

  // const handleAddInfluencerClick = () => {
  //   if (campaignId) {
  //     dispatch(
  //       postInfluencersToCampaign({
  //         campaignId: campaignId,
  //         influencerIds: selectedUserIds,
  //       })
  //     );
  //   }
  //   // onSave();
  // };

  const handleInfluencerDetail = (handle: any, userId: string) => {
    dispatch(
      modalOperation.showModal(ModalsType.ProfileModal, {
        onSave: (data: any) => {
          dispatch(modalOperation.hideModal());
        },
        onClose: () => {
          dispatch(modalOperation.hideModal());
          fetchInfluencers(filters);
        
        },
        title: "Detailed Profile",
        instaHandle: handle,
        userId: userId,
      })
    );
  };

  const handleFollowersChange = (
    value: string,
    customValues?: { min?: number; max?: number }
  ) => {
    const updatedFilters = {
      ...filters,
      followers: value,
      minFollowers: value === "custom" ? customValues?.min : undefined,
      maxFollowers: value === "custom" ? customValues?.max : undefined,
    };

    if (value !== "custom") {
      updatedFilters.followers = value;
    }

    // Validate custom values
    if (value === "custom" && customValues) {
      if (
        customValues.max &&
        customValues.min &&
        customValues.max <= customValues.min
      ) {
        toast.error("Max Followers should be greater than Min Followers");
        return;
      }
    }
    setCurrentPage(1);
    setSelectedAlphabet("ALL");
    setFilters(updatedFilters);

    // Prepare params for the API call
    const apiParams = {
      page: currentPage,
      pageSize: pageSize,
      sortByAlphabet: "ALL",
      ...Object.fromEntries(
        Object.entries(updatedFilters).filter(
          ([_, value]) => value !== undefined && value !== ""
        )
      ),
    };

    // Dispatch the API call
    dispatch(requestAllInfluencers(apiParams));
  };

  const handleClearAllFilters = () => {
    setFilters({
      location: "",
      gender: "",
      search: "",
      favouriteId: "",
      followers: "",
      minFollowers: undefined,
      maxFollowers: undefined,
      isRegistered: false,
    });
    setSelectedAlphabet("ALL");
    fetchInfluencers({} , "ALL" , 1);
  };

  const handlePagination = (e: any, { activePage }: any) => {
    setCurrentPage(activePage);
    dispatch(
      requestAllInfluencers({
        page: activePage,
        pageSize: pageSize,
        sortByAlphabet: selectedAlphabet
      })
    );
  };

  const handleButtonClick = () => {
    setShowOverlay(true);
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
    setSelectedUserIds([]);
  };

  useEffect(() => {
    setFilters({});
    fetchInfluencers(filters);
    dispatch(requestGenreOptions());

    dispatch(
      requestFavouritesList({
        page: currentPage,
        pageSize: 10,
      })
    );
  }, [currentPage, pageSize]);

  return (
    <HeaderLayout
      title="Influencer Discovery Page"
      breadcrumbs={[
        {
          text: "Influencer Discovery Page",
          url: URLRoutes.clients.influencerList,
        },
      ]}
      contentClassName="width-100 flex flex-column"
    >
      <div className="flex flex-column width-100 discovery-filters">
        <div className="flex flex-row width-100  flex-justify-between flex-align-center padding-t-2 padding-b-2 padding-l-4 padding-r-4">
          <CustomInput
            baseClassName="width-50"
            onChange={handleFilterChange}
            placeholder="Search through Influencer Name"
            id="search"
            name="search"
            initialValue={filters.search}
          />
          <CustomButton
            buttonText="Create Favourite"
            primaryButton
            textSmall
            circular
            baseclassname={"cursor-pointer"}
            handleClick={handleButtonClick}
            // disabled={!selectedUserIds.length}
          />
        </div>

        <div className="flex flex-row flex-justify-between width-100 border-bottom border-grey-shade-1 border-top padding-2 text-3 flex-align-center">
          <div className="text-3 flex flex-align-center  width-20">
            <SvgIcon
              name={CUSTOM_SVG_ICON.VerifiedTick}
              svgType={SVGType.CUSTOM}
              size={"small"}
              baseclassname={"text-orange-shade1"}
            />
            <div className="margin-t-1 margin-l-1"> Registered Influencer</div>
            <Checkbox
              toggle
              name="isRegistered"
              className="margin-l-2 margin-r-1"
              checked={filters.isRegistered}
              onChange={(e, data) =>
                handleFilterChange("isRegistered", data.checked as boolean)
              }
            />
          </div>
          <div className="flex flex-row flex-align-center flex-justify-between width-80 padding-l-1">
            <FollowersDropdown
              placeholder="Select Followers"
              onChange={handleFollowersChange}
              value={filters.followers as string}
              options={followersOptions}
              // maxValue={filters.}
            />
            <Dropdown
              selection
              placeholder="Favourites List"
              options={favouriteData?.records?.map((record: any) => ({
                key: record.id,
                text: record.name,
                value: record.id,
              }))}
              onChange={(event, data) =>
                handleFilterChange("favouriteId", data.value)
              }
              value={filters.favouriteId}
              clearable
            />
            <CustomInput
              inputClassName="location-Input"
              name="location"
              id="location"
              placeholder="Enter location"
              onChange={handleFilterChange}
              initialValue={filters.location}
            />
            <Dropdown
              selection
              placeholder="Select Gender"
              options={NewGenderOptions.map((option) => ({
                key: option.value,
                text: option.label,
                value: option.value,
              }))}
              value={filters.gender}
              onChange={(event, data) =>
                handleFilterChange("gender", data.value)
              }
              clearable
            />
            <CustomButton
              buttonText="Clear All"
              whiteBlueButton
              noOutline
              transparent
              textSmall
              handleClick={handleClearAllFilters}
            />
          </div>
        </div>
        <div className="flex width-100 padding-2 flex-align-center flex-justify-center">
          <span className="text-bold  width-10">Sort By: </span>
          <div className="width-80 flex flex-justify-between">
            {renderAlphabets()}
          </div>
        </div>
      </div>

      {!fetching && !error && (
        <CustomTable
          hasPagination
          tableData={records}
          columns={columns}
          tableError={error}
          tableMessage={error ? influencerMessage : "No Influencer Available"}
          sortTable={false}
          currentPage={currentPage}
          fetching={fetching || false}
          paginationData={paginationInfo}
          disablePagination={!data?.records?.length}
          handlePaginationChange={handlePagination}
          contentRowClassName="padding-2"
          tableClassName="discovery-table"
        />
      )}
      {error && (
        <div className="padding-6 text-6 text-bold flex flex-align-center flex-justify-center">
          {influencerMessage}
        </div>
      )}
      {fetching && <Loading isGlobal />}
      {showOverlay && (
        <FavouritesOverlay
          openOverlay={showOverlay}
          onCloseOverlay={handleCloseOverlay}
          influencerId={selectedUserIds}
        />
      )}
    </HeaderLayout>
  );
}

export default InfluencerList;
