import { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Table } from "semantic-ui-react";
import {
  postToInfluencers,
  requestCampaignInfluencerList,
  updateBillingDetails,
} from "store/rext";
import { getCampaignInfluencerListState } from "store/selectors";
import Loading from "components/Loading";
import { CustomInput } from "components/CustomInputs";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import {
  changeCollaborationStatus,
  formatNumber,
  getUserCategoryForCount,
} from "utils/utils";
import {
  CAMPAIGN_COLLABORATION_ACTIONS,
  CAMPAIGN_COLLABORATION_STATUS,
  ROLES,
} from "utils/Enums";
import CustomButton from "components/CustomButton";
import { modalOperation } from "store/actions";
import { ModalsType } from "containers/ModalManager/ModalManager";
import classNames from "classnames";
import "./style.scss";
import { ICON_POSITION } from "components/CustomButton/CustomButton";

enum Filters {
  Pending = "Pending",
  Approved = "Approved",
  Not_Shared_Yet = "Not Shared Yet",
  Assigned = "Assigned",
}

interface IProps {
  campaignId: string | undefined;
  handleCheckBoxChange: (data: any) => void;
  role: ROLES | undefined;
  features: any;
  validationMessage?: any;
  setSelectedUsersdata: any;
}

const contentViewOptions = [
  { text: "Data & Insights", value: "viewInsight", key: "viewInsight" },
];

function ShortlistedInfluencer({
  campaignId,
  handleCheckBoxChange,
  role,
  features,
  validationMessage,
  setSelectedUsersdata,
}: IProps) {
  const dispatch = useDispatch();
  const [activeFilters, setActiveFilters] = useState<Filters>(undefined!);
  const {
    data: campaignInfluencers,
    fetching,
    message,
    error,
  } = useSelector(getCampaignInfluencerListState);
  const { records } = campaignInfluencers || {};
  // const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<boolean>(false);


  const renderTable = (item: any) => {
    return (
      <Table.Row key={item.id} className={classNames([
              {
                "user-deleted-css":
                  item.influencerDetails?.user_deleted,
              },
      ])}>
        {features.getInfluencerDetailFeature().postToInfluencers && (
          <Table.Cell className="fix-table-cell fix-table-cell-corner">
            <input
              type="checkbox"
              onChange={() => handleCheckBoxChange(item.influencer_id)}
            />
          </Table.Cell>
        )}
        <Table.Cell
          className={classNames([
            "fix-table-cell",
            { "fix-table-cell-start": role === ROLES.BRAND },
            { "width-25": role === ROLES.BRAND && !selectedFilter },
          ])}
        >
          <div
            className={classNames([
              "flex flex-row flex-align-center margin-r-1 influencer-detail",
              {
                "width-100 margin-l-4":
                  role === ROLES.BRAND && !selectedFilter,
              },
            ])}
          >
            <div className="influencer-detail-img">
              <img
                src={
                  item.influencerDetails?.profile_picture_url ||
                  "/images/NoProfile.png"
                }
                alt="Profile"
                onError={handleImageError}
              />
            </div>
            <div className="flex flex-column flex-align-start  influencer-detail-profile">
              <div className="text1 text-align-left width-100">
                {item.influencerDetails?.name}
                {item.influencerDetails?.user_deleted && <div className="text1 text-align-left width-100">
                  <i style={{color:'red'}}>(User deleted)</i>
                </div>}
              </div>
              <div className="text2">
                {item.influencerDetails.followers_count
                  ? formatNumber(item.influencerDetails.followers_count)
                  : "-"}
                <span className="influencer-category text-2 margin-l-2">
                  {getUserCategoryForCount(
                    item.influencerDetails.followers_count
                  )}
                </span>
              </div>
            </div>
          </div>
        </Table.Cell>
        {features.getInfluencerDetailFeature().budget_edit && (
          <Table.Cell>
            <CustomInput
              name="budget"
              id={`budget-${item.id}`}
              placeholder="Budget"
              initialValue={item.budget}
              onChange={(name, value) =>
                handleBillingFieldsChange(name, value, item.id)
              }
              baseClassName="table-cell"
            />
          </Table.Cell>
        )}
        {features.getInfluencerDetailFeature().quote_edit && (
          <Table.Cell>
            <CustomInput
              name="quote"
              id={`quote-${item.id}`}
              placeholder="Quote"
              initialValue={item.quote}
              onChange={(name, value) =>
                handleBillingFieldsChange(name, value, item.id)
              }
              // baseClassName="width-50"
              baseClassName="table-cell"
            />
          </Table.Cell>
        )}
        {/* {features.getInfluencerDetailFeature().markup_edit && <Table.Cell>
      <CustomInput
        name="mark"
        id={`mark-${item.id}`}
        placeholder="Mark"
        initialValue={item.mark}
        onChange={(name, value) =>
          handleBillingFieldsChange("mark", value, item.id)
        }
        baseClassName="table-cell"
      />
    </Table.Cell>} */}
        {features.getInfluencerDetailFeature().final_edit && (
          <Table.Cell>
            <CustomInput
              name="final"
              id={`final-${item.id}`}
              placeholder="Final Quote"
              initialValue={item.final}
              onChange={(name, value) =>
                handleBillingFieldsChange(name, value, item.id)
              }
              baseClassName="table-cell"
            />
          </Table.Cell>
        )}
        {features.getInfluencerDetailFeature().takes_edit && (
          <Table.Cell>
            <CustomInput
              name="takes"
              id={`takes-${item.id}`}
              placeholder="Takes"
              initialValue={item.takes}
              onChange={(name, value) =>
                handleBillingFieldsChange(name, value, item.id)
              }
              baseClassName="table-cell"
            />
          </Table.Cell>
        )}
        {features.getInfluencerDetailFeature().final_view && (
          <Table.Cell className="width-18">{item.final || "-"}</Table.Cell>
        )}
        {features.getInfluencerDetailFeature().takes_view && (
          <Table.Cell className="width-18">{item.takes || "-"}</Table.Cell>
        )}
        {role === ROLES.HOB && (
          <Table.Cell className="position-relative">
            {item?.chatCountInfo && item?.chatCountInfo[0]?.count && (
              <div className="position-absolute chat-count">
                {item.chatCountInfo[0]?.count}
              </div>
            )}
            <SvgIcon
              name={CUSTOM_SVG_ICON.Chat}
              svgType={SVGType.CUSTOM}
              size={"huge"}
              baseclassname={"text-default-color text-align-center"}
              onClick={() => handleOpenChat(item)}
            />
          </Table.Cell>
        )}
        <Table.Cell
          className={classNames([
            "text-3",
            { "width-20": role === ROLES.BRAND && !selectedFilter },
          ])}
        >
          {changeCollaborationStatus(item.collaboration_status) || "-"}
        </Table.Cell>
        <Table.Cell>
          <div
            className={classNames([
              "flex margin-r-2 flex-align-center",
              {
                "width-35 flex-justify-between":
                  role === ROLES.BRAND && !selectedFilter,
              },
            ])}
          >
            <SvgIcon
              name={CUSTOM_SVG_ICON.AcceptIcon}
              svgType={SVGType.CUSTOM}
              size={"huge"}
              baseclassname={"text-green-shade-2 text-align-center margin-r-1 cursor-pointer"}
              onClick={() => handleAcceptInfluencer(item.influencerDetails.id)}
            />
            <SvgIcon
              name={CUSTOM_SVG_ICON.RejectIcon}
              svgType={SVGType.CUSTOM}
              size={"huge"}
              baseclassname={"text-danger-color text-align-center cursor-pointer"}
              onClick={() => handleRejectInfluencer(item.influencerDetails.id)}
            />
          </div>
        </Table.Cell>
        {selectedFilter && (
          <Table.Cell className="insights-bg">
            {`@${item.influencerDetails.instahandle}` || "-"}
          </Table.Cell>
        )}
        {selectedFilter  && (
          <Table.Cell className="insights-bg">
            {item.influencerDetails.follows_count
              ? formatNumber(item.influencerDetails.follows_count)
              : "-"}
          </Table.Cell>
        )}
        {selectedFilter && (
          <Table.Cell className="insights-bg">
            {item.influencerDetails.media_count
              ? formatNumber(item.influencerDetails.media_count)
              : "-"}
          </Table.Cell>
        )}
        {selectedFilter && (
          <Table.Cell className="insights-bg">
            {item.influencerDetails.avglikes
              ? formatNumber(item.influencerDetails.avglikes)
              : "-"}
          </Table.Cell>
        )}
        {selectedFilter && (
          <Table.Cell className="insights-bg">
            {item.influencerDetails.avgcomments
              ? formatNumber(item.influencerDetails.avgcomments)
              : "-"}
          </Table.Cell>
        )}
        {selectedFilter && (
          <Table.Cell className="insights-bg">-</Table.Cell>
        )}
        {selectedFilter &&(
          <Table.Cell className="insights-bg">-</Table.Cell>
        )}
        {selectedFilter && (
          <Table.Cell className="insights-bg">-</Table.Cell>
        )}
        {selectedFilter && (
          <Table.Cell className="insights-bg">-</Table.Cell>
        )}
        {selectedFilter && (
          <Table.Cell className="insights-bg">-</Table.Cell>
        )}
        {selectedFilter && (
          <Table.Cell className="insights-bg">-</Table.Cell>
        )}
        {selectedFilter && (
          <Table.Cell className="insights-bg">-</Table.Cell>
        )}
        {selectedFilter && (
          <Table.Cell className="insights-bg">-</Table.Cell>
        )}
      </Table.Row>
    );
  };

  const handleApplyFilter = () => {
    setSelectedFilter(!selectedFilter)
    // if(selectedFilter) {
    // dispatch(
    //   requestCampaignInfluencerList({
    //     page: 1,
    //     pageSize: 100,
    //     campaignId: campaignId,
    //     collaborationStatus: [
    //       CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_HOB,
    //       CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_BRAND,
    //       CAMPAIGN_COLLABORATION_STATUS.APPLY_CAMPAIGN,
    //       CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_BRAND,
    //       CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_HOB,
    //       CAMPAIGN_COLLABORATION_STATUS.POST_TO_INFLUENCER,
    //       CAMPAIGN_COLLABORATION_STATUS.PENDING,
    //       CAMPAIGN_COLLABORATION_STATUS.DECLINE_CAMPAIGN,
    //     ],
    //      viewInsight: true
    //   })
    // );
  // }
  }

  // const handleContentViewChange = (event: any, data: any) => {
  //   const selectedValues = data.value as string[];
  //   setSelectedOptions(selectedValues);
  //   const AllFilters = selectedValues.reduce((acc: any, value) => {
  //     acc[value] = true;
  //     return acc;
  //   }, {});

  //   dispatch(
  //     requestCampaignInfluencerList({
  //       page: 1,
  //       pageSize: 100,
  //       campaignId: campaignId,
  //       collaborationStatus: [
  //         CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_HOB,
  //         CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_BRAND,
  //         CAMPAIGN_COLLABORATION_STATUS.APPLY_CAMPAIGN,
  //         CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_BRAND,
  //         CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_HOB,
  //         CAMPAIGN_COLLABORATION_STATUS.POST_TO_INFLUENCER,
  //         CAMPAIGN_COLLABORATION_STATUS.PENDING,
  //         CAMPAIGN_COLLABORATION_STATUS.DECLINE_CAMPAIGN,
  //       ],
  //       ...AllFilters,
  //     })
  //   );
  // };

  const handleFilterChange = (filterName: Filters) => {
    setActiveFilters(filterName);
    const requestParams: any = {
      page: 1,
      pageSize: 100,
      campaignId: campaignId,
    };

    if (filterName === Filters.Not_Shared_Yet) {
      requestParams.collaborationStatus = [
        CAMPAIGN_COLLABORATION_STATUS.PENDING,
      ];
    } else if (filterName === Filters.Approved) {
      requestParams.collaborationStatus = [
        CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_BRAND,
        CAMPAIGN_COLLABORATION_STATUS.APPLY_CAMPAIGN,
      ];
    } else if (filterName === Filters.Pending) {
      requestParams.collaborationStatus = [
        CAMPAIGN_COLLABORATION_STATUS.POST_TO_INFLUENCER,
      ];
    } else if (filterName === Filters.Assigned) {
      requestParams.collaborationStatus = [
        CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_HOB,
      ];
    } else if (filterName === activeFilters) {
      requestParams.collaborationStatus = [
        CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_BRAND,
        CAMPAIGN_COLLABORATION_STATUS.APPLY_CAMPAIGN,
        CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_BRAND,
        CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_HOB,
        CAMPAIGN_COLLABORATION_STATUS.POST_TO_INFLUENCER,
        CAMPAIGN_COLLABORATION_STATUS.PENDING,
        CAMPAIGN_COLLABORATION_STATUS.DECLINE_CAMPAIGN,
      ];
    }

    dispatch(requestCampaignInfluencerList(requestParams));
  };

  const removeFilter = () => {
    setActiveFilters(undefined!);
    dispatch(
      requestCampaignInfluencerList({
        page: 1,
        pageSize: 100,
        campaignId: campaignId,
        collaborationStatus: [
          CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_HOB,
          CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_BRAND,
          CAMPAIGN_COLLABORATION_STATUS.APPLY_CAMPAIGN,
          CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_BRAND,
          CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_HOB,
          CAMPAIGN_COLLABORATION_STATUS.POST_TO_INFLUENCER,
          CAMPAIGN_COLLABORATION_STATUS.PENDING,
          CAMPAIGN_COLLABORATION_STATUS.DECLINE_CAMPAIGN,
        ],
      })
    );
  };

  const handleBillingFieldsChange = useCallback(
    (name: string, value: any, id: any) => {
      dispatch(updateBillingDetails({ [name]: value }, { id: id }));
      // if (name === "budget" && value > 0) {
      //   setTimeout(() => {
      //     dispatch(
      //       requestCampaignInfluencerList({
      //         page: 1,
      //         pageSize: 100,
      //         campaignId: campaignId,
      //         collaborationStatus: [
      //           CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_BRAND,
      //           CAMPAIGN_COLLABORATION_STATUS.APPLY_CAMPAIGN,
      //           CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_BRAND,
      //           CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_HOB,
      //           CAMPAIGN_COLLABORATION_STATUS.POST_TO_INFLUENCER,
      //           CAMPAIGN_COLLABORATION_STATUS.PENDING,
      //           CAMPAIGN_COLLABORATION_STATUS.DECLINE_CAMPAIGN,
      //           CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_HOB,
      //         ],
      //       })
      //     );
      //   }, 2000);
      // }
    },
    [dispatch]
  );

  const handleAcceptInfluencer = (id: any) => {
    // const currentRecordBudget = records.find(
    //   (record: any) => record.influencerDetails.id === id
    // ).budget;
    // if (!currentRecordBudget && role !== ROLES.BRAND) {
    //   toast.warn(
    //     "Please fill in the budget for the selected influencer(s) before posting.",
    //     {
    //       closeButton: true,
    //       closeOnClick: true,
    //       autoClose: 30000,
    //       position: "top-center",
    //       style: { width: 600 },
    //     }
    //   );
    //   return;
    // }
    dispatch(
      postToInfluencers({
        campaignId: campaignId,
        influencerIds: [id],
        action:
          role === ROLES.BRAND
            ? CAMPAIGN_COLLABORATION_ACTIONS.ACCEPT_BY_BRAND
            : CAMPAIGN_COLLABORATION_ACTIONS.ACCEPT_BY_HOB,
      })
    );
  };

  const handleOpenChat = (data: any) => {
    dispatch(
      modalOperation.showModal(ModalsType.ChatModal, {
        onSave: (data: any) => {
          dispatch(modalOperation.hideModal());
        },
        onClose: () => {
          dispatch(modalOperation.hideModal());
        },
        toggle: true,
        influencerData: data,
      })
    );
  };
  const handleRejectInfluencer = (id: any) => {
    dispatch(
      postToInfluencers({
        campaignId: campaignId,
        influencerIds: [id],
        action:
          role === ROLES.BRAND
            ? CAMPAIGN_COLLABORATION_ACTIONS.DECLINE_BY_BRAND
            : CAMPAIGN_COLLABORATION_ACTIONS.DECLINE_BY_HOB,
      })
    );
    // toast.success(statusMessage)
  };

  const handleImageError = (e: any) => {
    e.target.onerror = null;
    e.target.src = "/images/NoProfile.png";
  };

  useEffect(() => {
    setSelectedUsersdata([]);
    if (selectedFilter) {
      // const appliedFilters = selectedOptions.reduce((acc: any, value) => {
      //   acc[value] = true;
      //   return acc;
      // }, {});
      dispatch(
        requestCampaignInfluencerList({
          page: 1,
          pageSize: 100,
          campaignId: campaignId,
          collaborationStatus: [
            CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_BRAND,
            CAMPAIGN_COLLABORATION_STATUS.APPLY_CAMPAIGN,
            CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_BRAND,
            CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_HOB,
            CAMPAIGN_COLLABORATION_STATUS.POST_TO_INFLUENCER,
            CAMPAIGN_COLLABORATION_STATUS.PENDING,
            CAMPAIGN_COLLABORATION_STATUS.DECLINE_CAMPAIGN,
            CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_HOB,
          ],
          viewInsight: true,
        })
      );
    } else {
      dispatch(
        requestCampaignInfluencerList({
          page: 1,
          pageSize: 100,
          campaignId: campaignId,
          collaborationStatus: [
            CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_BRAND,
            CAMPAIGN_COLLABORATION_STATUS.APPLY_CAMPAIGN,
            CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_BRAND,
            CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_HOB,
            CAMPAIGN_COLLABORATION_STATUS.POST_TO_INFLUENCER,
            CAMPAIGN_COLLABORATION_STATUS.PENDING,
            CAMPAIGN_COLLABORATION_STATUS.DECLINE_CAMPAIGN,
            CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_HOB,
          ],
        })
      );
    }
  }, [campaignId, validationMessage, selectedFilter]);

  return (
    <Fragment>
      <div className="width-100 flex flex-wrap flex-row flex-justify-between margin-b-3">
        {records && records.length > 0 && <div className="flex width-65 padding-2 flex-align-center">
          <span className="text-3 text-bold width-8">Filters:</span>
          <CustomButton
            buttonText="Not Shared Yet"
            notBold
            greyButton
            transparent={activeFilters !== Filters.Not_Shared_Yet}
            textSmall
            handleClick={() => handleFilterChange(Filters.Not_Shared_Yet)}
          />
          <CustomButton
            buttonText="Pending"
            notBold
            greyButton
            transparent={activeFilters !== Filters.Pending}
            textSmall
            handleClick={() => handleFilterChange(Filters.Pending)}
          />
          <CustomButton
            buttonText="Approved"
            notBold
            greyButton
            transparent={activeFilters !== Filters.Approved}
            textSmall
            handleClick={() => handleFilterChange(Filters.Approved)}
          />
          <CustomButton
            buttonText="Assigned"
            notBold
            greyButton
            transparent={activeFilters !== Filters.Assigned}
            textSmall
            handleClick={() => handleFilterChange(Filters.Assigned)}
          />
          {activeFilters && (
            <CustomButton
              buttonText="Clear All"
              secondaryButton
              textSmall
              circular
              handleClick={removeFilter}
              baseclassname={"padding-l-3"}
            />
          )}
        </div>}
        {records && records.length > 0 && <div className="width-30 flex flex-row flex-align-center flex-justify-end">
          {/* <Dropdown
            placeholder="Select"
            floating
            selection
            value={selectedOptions}
            onChange={handleContentViewChange}
            className="margin-t-2 influencer-options width-100"
            options={contentViewOptions}
            multiple
            disabled={!records?.length || error}
            /> */}
            <CustomButton
            buttonText="Data & Insights"
            handleClick={handleApplyFilter}
            textSmall
            circular
            greyButton={!selectedFilter}
            secondaryButton={selectedFilter}
            />
        </div>}
      </div>
      <div className="user-container flex flex flex-column margin-r-2">
        {campaignInfluencers.records?.length > 0 && !error && !fetching && (
          <Table
            basic="very"
            collapsing
            className={classNames([
              "influencer-table",
              { "influencer-table-cells": role === ROLES.BRAND },
            ])}
          >
            <Table.Header>
              <Table.Row>
                {features.getInfluencerDetailFeature().postToInfluencers && (
                  <Table.HeaderCell className="fix-table-head fix-table-head-corner"></Table.HeaderCell>
                )}
                <Table.HeaderCell
                  className={classNames([
                    "fix-table-head",
                    { "fix-table-head-start": role === ROLES.BRAND },
                  ])}
                >
                  Influencer Name
                </Table.HeaderCell>
                {features.getInfluencerDetailFeature().budget_edit && (
                  <Table.HeaderCell>Budget</Table.HeaderCell>
                )}
                {features.getInfluencerDetailFeature().quote_edit && (
                  <Table.HeaderCell>Quote</Table.HeaderCell>
                )}
                {/* {features.getInfluencerDetailFeature().markup_edit && <Table.HeaderCell>Markup</Table.HeaderCell>} */}
                <Table.HeaderCell>Final Quote</Table.HeaderCell>
                <Table.HeaderCell>#Takes</Table.HeaderCell>
                {role === ROLES.HOB && (
                  <Table.HeaderCell>Chat</Table.HeaderCell>
                )}
                <Table.HeaderCell>Collaboration Status</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
                {selectedFilter && (
                  <Table.HeaderCell className="insights-bg">
                    Insta Handle
                  </Table.HeaderCell>
                )}
                {selectedFilter && (
                  <Table.HeaderCell className="insights-bg">
                    Following
                  </Table.HeaderCell>
                )}
                {selectedFilter && (
                  <Table.HeaderCell className="insights-bg">
                    Media Count
                  </Table.HeaderCell>
                )}
                {selectedFilter && (
                  <Table.HeaderCell className="insights-bg">
                    Average Likes
                  </Table.HeaderCell>
                )}
                {selectedFilter && (
                  <Table.HeaderCell className="insights-bg">
                    Average Comments
                  </Table.HeaderCell>
                )}
                {selectedFilter && (
                  <Table.HeaderCell className="insights-bg">
                    Average Views
                  </Table.HeaderCell>
                )}
                {selectedFilter && (
                  <Table.HeaderCell className="insights-bg">
                    Engagement Ratio
                  </Table.HeaderCell>
                )}
                {selectedFilter && (
                  <Table.HeaderCell className="insights-bg">
                    Genres
                  </Table.HeaderCell>
                )}
                {selectedFilter && (
                  <Table.HeaderCell className="insights-bg">
                    Audience Location
                  </Table.HeaderCell>
                )}
                {selectedFilter && (
                  <Table.HeaderCell className="insights-bg">
                    Audience Gender
                  </Table.HeaderCell>
                )}
                {selectedFilter && (
                  <Table.HeaderCell className="insights-bg">
                    Audience Age
                  </Table.HeaderCell>
                )}
                {selectedFilter && (
                  <Table.HeaderCell className="insights-bg">
                    Estimated Reach
                  </Table.HeaderCell>
                )}
                {selectedFilter && (
                  <Table.HeaderCell className="insights-bg">
                    Estimated Impressions
                  </Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>{(records || []).map(renderTable)}</Table.Body>
          </Table>
        )}
        {error && <div className="flex flex-align-center flex-justify-center width-100 height-100 text-bold text-5">{message}</div>}
        {/* {!error && !campaignInfluencers?.records?.length && (
          <div>{message}</div>
        )} */}
        {fetching && <Loading />}
      </div>
    </Fragment>
  );
}

export default ShortlistedInfluencer;
