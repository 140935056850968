import { Controller } from "react-hook-form";
import classNames from "classnames";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { IconSizeProp } from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";

import "./HookForms.scss";

interface IICONPROPS {
  svgType: SVGType;
  circular?: boolean;
  name: any;
  size?: IconSizeProp;
  baseclassname?: any;
  inverted?: boolean;
}

interface IProps {
  label?: string;
  labelClassName?: string;
  baseClassName?: string;
  name: string;
  validation?: any;
  control: any;
  errors: any;
  inputClassName?: string;
  id: string;
  triggerOnChange?: any;
  placeholder?: string;
  iconProps?: IICONPROPS;
  type?: string;
  disabled?: boolean;
  onBlur?: any;
  iconClassName?: string;
  InputRef?: any;
  caption?: string;
  required?: boolean;
}

export function HookFormInput(props: IProps) {
  const {
    label,
    labelClassName,
    baseClassName,
    name,
    validation,
    control,
    errors,
    inputClassName,
    id,
    triggerOnChange,
    placeholder,
    iconProps,
    type,
    disabled,
    onBlur,
    iconClassName,
    InputRef,
    caption, 
    required
  } = props;

const getErrorMessage = (field: string) => {
  const errorPath = field.split('.');
  let currentError = errors as any;

  for (const path of errorPath) {
    const arrayMatch = path.match(/(\w+)\[(\d+)\]/);
    if (arrayMatch) {
      const [ , arrayName, index ] = arrayMatch;
      if (currentError && Array.isArray(currentError[arrayName])) {
        currentError = currentError[arrayName][parseInt(index, 10)];
      } else {
        currentError = undefined;
        break;
      }
    } else if (currentError && currentError[path] !== undefined) {
      currentError = currentError[path];
    } else {
      currentError = undefined;
      break;
    }
  }

  if (currentError && typeof currentError === 'object' && 'message' in currentError) {
    return currentError.message;
  }

  return '';
};

  return (
    <div
      className={classNames([
        "flex flex-column custom-input position-relative",
        baseClassName,
      ])}
    >
      {label && (
        <div
          className={classNames(["input-label padding-b-1 position-relative", labelClassName])}
        >
          {label}
          { required && <span className={classNames(["position-absolute input-asterik text-danger-color "])}>&#8727;</span>}
        </div>
      )}

      {caption && (
        <div
          className={classNames(["text-2"])}
        >
          {caption}
        </div>
      )}
      {control && (
        <Controller
          control={control}
          name={name}
          rules={validation || {}}
          render={({ field: { onChange, value, ref } }) => (
            <>
              {iconProps && (
                <SvgIcon
                  {...iconProps}
                  baseclassname={classNames([
                    { "input-icon-stable": errors[name] },
                    { "input-icon": !errors[name] },
                    iconClassName || "",
                  ])}
                />
              )}
              <input
                ref={InputRef || undefined!}
                type={type || "text"}
                value={value || ""}
                id={id}
                placeholder={placeholder}
                disabled={disabled}
                className={classNames(
                  { "padding-l-10": iconProps },
                  { "padding-l-4": !iconProps },
                  inputClassName || ""
                )}
                onBlur={onBlur}
                onChange={(e) => {
                  onChange(e.target.value);
                  if (typeof triggerOnChange === "function") {
                    triggerOnChange(e.target.value);
                  }
                }}
              />
            </>
          )}
        />
      )}
      {getErrorMessage(name) &&  (
        <div className="text-3 text-danger-color padding-b-1 padding-l-1">
          {getErrorMessage(name)}
        </div>
      )}
       {/* {errors[name] && (
        <div className="text-3 text-danger-color padding-b-1 padding-l-1">
          {errors[name].message}
        </div>
      )} */}
    </div>
  );
}
