import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Table } from "semantic-ui-react";
import { toast } from "react-toastify";
import Loading from "components/Loading";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { ordinalNumbers } from "containers/Campaigns/utils";
import { ModalsType } from "containers/ModalManager/ModalManager";
import { modalOperation } from "store/actions";
import {
  changeShipmentStatus,
  postToInfluencers,
  requestCampaignInfluencerList,
  updateBillingDetails,
} from "store/rext";
import {
  getBillingInfluencersState,
  getCampaignInfluencerListState,
  getPostedInfluencersState,
  getUploadedVideoState,
} from "store/selectors";
import {
  CAMPAIGN_COLLABORATION_ACTIONS,
  CAMPAIGN_COLLABORATION_STATUS,
  CampaignType,
  PAYMENT_STATUS,
  ROLES,
  SHIPMENT_STATUS,
} from "utils/Enums";
import {
  changeCollaborationStatus,
  formatDate,
  formatNumber,
  formatStatusString,
  getUserCategoryForCount,
} from "utils/utils";
import UsePrevious from "HOC/UsePrevious";
import classNames from "classnames";

const paymentStatusOptions = [
  { text: "Issue", value: PAYMENT_STATUS.Issue, key: PAYMENT_STATUS.Issue },
  { text: "Paid", value: PAYMENT_STATUS.Paid, key: PAYMENT_STATUS.Paid },
  {
    text: "Pending",
    value: PAYMENT_STATUS.Pending,
    key: PAYMENT_STATUS.Pending,
  },
  { text: "NA", value: PAYMENT_STATUS.NA, key: PAYMENT_STATUS.NA },
];

const shipmentStatusOptions = [
  {
    text: "Delivered",
    value: SHIPMENT_STATUS.DELIVERED,
    key: SHIPMENT_STATUS.DELIVERED,
  },
  {
    text: "In Transit",
    value: SHIPMENT_STATUS.IN_TRANSIT,
    key: SHIPMENT_STATUS.IN_TRANSIT,
  },
  {
    text: "Out For Delivery",
    value: SHIPMENT_STATUS.OUT_FOR_DELIVERY,
    key: SHIPMENT_STATUS.OUT_FOR_DELIVERY,
  },
  {
    text: "Pickup Complete",
    value: SHIPMENT_STATUS.PICKUP_COMPLETE,
    key: SHIPMENT_STATUS.PICKUP_COMPLETE,
  },
  {
    text: "Pickup Scheduled",
    value: SHIPMENT_STATUS.PICKUP_SCHEDULED,
    key: SHIPMENT_STATUS.PICKUP_SCHEDULED,
  },
  {
    text: "Return Complete",
    value: SHIPMENT_STATUS.RETURN_COMPLETE,
    key: SHIPMENT_STATUS.RETURN_COMPLETE,
  },
  { text: "RTO", value: SHIPMENT_STATUS.RTO, key: SHIPMENT_STATUS.RTO },
];

const contentViewOptions = [
  { text: "Content", value: "viewContent", key: "viewContent" },
  { text: "Data & Insights", value: "viewInsight", key: "viewInsight" },
  { text: "Payment", value: "viewPayment", key: "viewPayment" },
];

interface IProps {
  campaignId: string | undefined;
  features: any;
  role: ROLES | undefined;
  campaignType: string;
  setSelectedUsersdata: any;
}

function FinalisedInfluencers({
  campaignId,
  features,
  role,
  campaignType,
  setSelectedUsersdata
}: IProps) {
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [paymentStatus, setPaymentStatus] = useState<{ [key: string]: string }>({});
  const [shipmentStatus, setShipmentStatus] = useState<{[key: string]: string;}>({});
  const {    fetching: shipmentFetching  } = useSelector(getPostedInfluencersState);
  const {
    message: paymentMessage,
    fetching: paymentFetching,
    error: paymentError,
  } = useSelector(getBillingInfluencersState);
  const {
    data: campaignInfluencers,
    fetching,
    message,
    error,
  } = useSelector(getCampaignInfluencerListState);
  const { records } = campaignInfluencers || {};
  const {
    message: videoMessage,
    fetching: videoFetching,
    error: videoError,
  } = useSelector(getUploadedVideoState);
  const wasFetching = UsePrevious(videoFetching);
  const wasPaymentFetching = UsePrevious(paymentFetching);

  const finalizedFilters = (campaignType === CampaignType.PAID_UGC ||
    campaignType === CampaignType.BARTER_UGC)  ? [...contentViewOptions , { text: "Logistics", value: "viewLogistics", key: "viewLogistics"}] : contentViewOptions ;
 
    const renderTable = (item: any) => {
    return (
      <Table.Row key={item.id} className={classNames([
              {
                "user-deleted-css":
                  item.influencerDetails?.user_deleted,
              },
      ])}>
        <Table.Cell className={classNames(["fix-table-cell fix-table-cell-start", {"width-25": role === ROLES.BRAND }])}>
          <div
            className={classNames([
              "flex flex-row flex-align-center margin-r-1 influencer-detail",
              {
                " width-100": role === ROLES.BRAND ,
              },
            ])}
          >
            <div className="influencer-detail-img">
              <img
                src={
                  item.influencerDetails?.profile_picture_url ||
                  "/images/NoProfile.png"
                }
                alt="Profile"
                onError={handleImageError}
              />
            </div>
            <div className="flex flex-column flex-align-start  influencer-detail-profile">
              <div className="text1">{item.influencerDetails?.name}
                {item.influencerDetails?.user_deleted && <div className="text1 text-align-left width-100">
                  <i style={{color:'red'}}>(User deleted)</i>
                </div>}
              </div>
              <div className="text2">
                {item.influencerDetails.followers_count
                  ? formatNumber(item.influencerDetails.followers_count)
                  : "-"}
                <span className="influencer-category text-2 margin-l-2">
                  {getUserCategoryForCount(
                    item.influencerDetails.followers_count
                  )}
                </span>
              </div>
            </div>
          </div>
        </Table.Cell>
        {role !== ROLES.BRAND && <Table.Cell>{item.budget || "-"}</Table.Cell>}
        {role !== ROLES.BRAND && <Table.Cell>{item.quote || "-"}</Table.Cell>}
        {/* {role !== ROLES.BRAND && (
        <Table.Cell>{item.mark || "-"}</Table.Cell>
      )} */}
        <Table.Cell>{item.final || "-"}</Table.Cell>
        <Table.Cell>{item.takes || "-"}</Table.Cell>
        {role === ROLES.HOB && (
          <Table.Cell className="position-relative">
            {item.chatCountInfo[0]?.count && (
              <div className="position-absolute chat-count">
                {item.chatCountInfo[0]?.count}
              </div>
            )}
            <SvgIcon
              name={CUSTOM_SVG_ICON.Chat}
              svgType={SVGType.CUSTOM}
              size={"huge"}
              baseclassname={"text-default-color text-align-center "}
              onClick={() => handleOpenChat(item)}
            />
          </Table.Cell>
        )}
        <Table.Cell className="text-3">
          {changeCollaborationStatus(item.collaboration_status) || "-"}
        </Table.Cell>
        <Table.Cell>
          <div
            className={classNames([
              "flex margin-r-2 flex-align-center",
              {
                "flex-justify-between width-100": role === ROLES.BRAND,
              },
            ])}
          >
            {/* <SvgIcon
            name={CUSTOM_SVG_ICON.AcceptIcon}
            svgType={SVGType.CUSTOM}
            size={"huge"}
            baseclassname={
              "text-green-shade-2 text-align-center margin-r-1"
            }
            onClick={() =>
              handleAcceptInfluencer(item.influencerDetails.id)
            }
          /> */}
            <SvgIcon
              name={CUSTOM_SVG_ICON.RejectIcon}
              svgType={SVGType.CUSTOM}
              size={"huge"}
              baseclassname={"text-danger-color text-align-center"}
              onClick={() => handleRejectInfluencer(item.influencerDetails.id)}
            />
          </div>
        </Table.Cell>

        {selectedOptions.includes("viewInsight") && (
          <Table.Cell className="insights-bg">
            {`@${item.influencerDetails.instahandle}` || "-"}{" "}
          </Table.Cell>
        )}
        {selectedOptions.includes("viewInsight") && (
          <Table.Cell className="insights-bg">
            {item.influencerDetails.follows_count
              ? formatNumber(item.influencerDetails?.follows_count)
              : "-"}
          </Table.Cell>
        )}
        {selectedOptions.includes("viewInsight") && (
          <Table.Cell className="insights-bg">
            {item.influencerDetails.media_count
              ? formatNumber(item.influencerDetails?.media_count)
              : "-"}
          </Table.Cell>
        )}
        {selectedOptions.includes("viewInsight") && (
          <Table.Cell className="insights-bg">
            {item.influencerDetails.avglikes
              ? formatNumber(item.influencerDetails?.avglikes)
              : "-"}
          </Table.Cell>
        )}
        {selectedOptions.includes("viewInsight") && (
          <Table.Cell className="insights-bg">
            {item.influencerDetails.avgcomments
              ? formatNumber(item.influencerDetails?.avgcomments)
              : "-"}
          </Table.Cell>
        )}
          {selectedOptions.includes("viewInsight") && (
          <Table.Cell className="insights-bg">-</Table.Cell>
        )}
        {selectedOptions.includes("viewInsight") && (
          <Table.Cell className="insights-bg">-</Table.Cell>
        )}
        {selectedOptions.includes("viewInsight") && (
          <Table.Cell className="insights-bg">-</Table.Cell>
        )}
        {selectedOptions.includes("viewInsight") && (
          <Table.Cell className="insights-bg">-</Table.Cell>
        )}
        {selectedOptions.includes("viewInsight") && (
          <Table.Cell className="insights-bg">-</Table.Cell>
        )}
        {selectedOptions.includes("viewInsight") && (
          <Table.Cell className="insights-bg">-</Table.Cell>
        )}
        {selectedOptions.includes("viewInsight") && (
          <Table.Cell className="insights-bg">-</Table.Cell>
        )}
        {selectedOptions.includes("viewInsight") && (
          <Table.Cell className="insights-bg">-</Table.Cell>
        )}
        {selectedOptions.includes("viewContent") && (
          <Table.Cell className="content-bg">
            {item.videos && item.videocount > 0 ? (
              <div
                className="flex flex-column flex-align-center cursor-pointer influencer-address"
                onClick={() => handleFileNameClick(item.id)}
              >
                {/* <span className="text-3 text-grey-shade-4">
                {ordinalNumbers[item.videos.length - 1]} Draft
              </span> */}
                <div className="text-underline text-3 text-blue-color text-word-break text-align-center">
                  {item.videos[0].file_name}
                </div>
              </div>
            ) : <div className="text-align-center text-3 text-grey-shade-4">NA</div>}
          </Table.Cell>
        )}
        {selectedOptions.includes("viewContent") && (
          <Table.Cell className="content-bg">
            {item?.videos && item?.videocount > 0 ? (
                <div className="text-3 text-grey-shade-4">
                 {formatStatusString(`${ordinalNumbers[item.videocount - 1]} Draft ${item?.videos[0]?.status?.map((item: any) => item.status)}`)}
                </div>
            ) : <div className="text-align-center text-3 text-grey-shade-4">NA</div>}
          </Table.Cell>
        )}
        {selectedOptions.includes("viewContent") && (
          <Table.Cell className="text-align-center text-3 text-grey-shade-4 content-bg">{(item.videocount > 0 && item.golivedate) ? formatDate(item.golivedate) : "Yet to Go-Live"}</Table.Cell>
        )}
        {selectedOptions.includes("viewContent") && (
          <Table.Cell className="text-align-center text-3 text-grey-shade-4 content-bg">{item?.videos[0]?.status?.map((item: any) => item.status) === "REJECTED" ? item?.videos[0]?.status?.map((item: any) => item.reason) : "NA"}</Table.Cell>
        )}
        {selectedOptions.includes("viewContent") &&
          (campaignType === CampaignType.PAID_UGC ||
            campaignType === CampaignType.BARTER_UGC) && (
            <Table.Cell className="content-bg">-</Table.Cell>
          )}
        {selectedOptions.includes("viewLogistics") && (
          <Table.Cell className="logistics-bg">
            {role === ROLES.BRAND && item.shipment_details?.shipment_status}
            {role === ROLES.HOB && item.shipment_details?.id && (
              <Dropdown
                compact
                floating
                placeholder="Change Status"
                selection
                value={
                  shipmentStatus[item.shipment_details?.id] ||
                  item.shipment_details?.shipment_status
                }
                options={shipmentStatusOptions}
                className="status-dropdown"
                onChange={(event, data) =>
                  handleShipmentStatusChange(event, {
                    value: data.value,
                    id: item.shipment_details?.id,
                  })
                }
              />
            )}
          </Table.Cell>
        )}
        {selectedOptions.includes("viewLogistics") && (
          <Table.Cell className="logistics-bg">
            {item?.shipment_details && item.shipment_details.id ? (
              <div className="text-3 flex influencer-address">
                {item.shipment_details.address_line || ""}
              </div>
            ) : (
              "-"
            )}
          </Table.Cell>
        )}
        {selectedOptions.includes("viewPayment") && (
          <Table.Cell className="payment-bg">
            {role === ROLES.BRAND && <div className="text-align-center text-3 text-grey-shade-4">{formatStatusString(item?.payment_status)}</div>}
            {role === ROLES.HOB && item?.payment_status && (
              <Dropdown
                placeholder="Change Status"
                compact
                floating
                scrolling
                selection
                value={paymentStatus[item.id] || item.payment_status}
                options={paymentStatusOptions}
                className="status-dropdown"
                onChange={(event, data) =>
                  handlePaymentChange(event, {
                    value: data.value,
                    id: item.id,
                  })
                }
              />
            )}
          </Table.Cell>
        )}
      
      </Table.Row>
    );}

    const handleImageError = (e: any) => {
      e.target.onerror = null;
      e.target.src = "/images/NoProfile.png";
    };

  const handleContentViewChange = (event: any, data: any) => {
    const selectedValues = data.value as string[];
    setSelectedOptions(selectedValues);
    const AllFilters = selectedValues.reduce((acc: any, value) => {
      acc[value] = true;
      return acc;
    }, {});

    dispatch(
      requestCampaignInfluencerList({
        page: 1,
        pageSize: 10,
        campaignId: campaignId,
        collaborationStatus: [CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_HOB],
        ...AllFilters,
      })
    );
  };

  const handleAcceptInfluencer = (id: any) => {
    dispatch(
      postToInfluencers({
        campaignId: campaignId,
        influencerIds: [id],
        action:
          role === ROLES.BRAND
            ? CAMPAIGN_COLLABORATION_ACTIONS.ACCEPT_BY_BRAND
            : CAMPAIGN_COLLABORATION_ACTIONS.ACCEPT_BY_HOB,
      })
    );
    // toast.success(statusMessage)
  };

  const handleRejectInfluencer = (id: any) => {
    dispatch(
      postToInfluencers({
        campaignId: campaignId,
        influencerIds: [id],
        action:
          role === ROLES.BRAND
            ? CAMPAIGN_COLLABORATION_ACTIONS.DECLINE_BY_BRAND
            : CAMPAIGN_COLLABORATION_ACTIONS.DECLINE_BY_HOB,
      })
    );
    // toast.success(statusMessage)
  };

  const handlePaymentChange = (event: any, { value, id }: any) => {
    setPaymentStatus((prev) => ({ ...prev, [id]: value }));
    dispatch(updateBillingDetails({ payment_status: value }, { id }));
    // toast.info(paymentMessage)
  };

  const handleShipmentStatusChange = (event: any, { value, id }: any) => {
    setShipmentStatus((prev) => ({ ...prev, [id]: value }));
    dispatch(changeShipmentStatus({ shipment_status: value }, { id }));
    // toast.info(influencerMessage);
  };

  const handleFileNameClick = (id: any) => {
    dispatch(
      modalOperation.showModal(ModalsType.VideoModal, {
        onSave: (data: any) => {
          dispatch(modalOperation.hideModal());
        },
        onClose: () => {
          dispatch(modalOperation.hideModal());
        },
        title: "Video List",
        toggle: () => {
          dispatch(modalOperation.hideModal());
        },
        Influencer_Id: id,
        allowedFileTypes: [".mp4"],
      })
    );
  };
  const handleOpenChat = (data: any) => {
    dispatch(
      modalOperation.showModal(ModalsType.ChatModal, {
        onSave: (data: any) => {
          dispatch(modalOperation.hideModal());
        },
        onClose: () => {
          dispatch(modalOperation.hideModal());
        },
        toggle: true,
        influencerData: data,
      })
    );
  };

  useEffect(() => {
    if (wasPaymentFetching && !paymentFetching && !paymentError) {
      toast.success(paymentMessage);
    } else if (paymentError) {
      toast.error(paymentMessage);
    }
  }, [paymentFetching, paymentError, paymentMessage]);

  useEffect(() => {
    if (wasFetching && !videoFetching && !videoError) {
      toast.success(videoMessage);
    } else if (videoError) {
      toast.error(videoMessage);
    }
  }, [videoFetching, videoError, videoMessage]);

  useEffect(() => {
        setSelectedUsersdata([])
    if(!selectedOptions.length) {
      dispatch(
        requestCampaignInfluencerList({
          page: 1,
          pageSize: 10,
          campaignId: campaignId,
          collaborationStatus: [CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_HOB],
        })
      );
    } else {
      const appliedFilters = selectedOptions.reduce((acc: any, value) => {
        acc[value] = true;
        return acc;
      }, {});
    dispatch(
      requestCampaignInfluencerList({
        page: 1,
        pageSize: 10,
        campaignId: campaignId,
        collaborationStatus: [CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_HOB],
        ...appliedFilters
      })
    );
  }
  }, [dispatch, campaignId , selectedOptions]);

  return (
    <Fragment>
      <div className="width-100 flex flex-row flex-justify-end margin-b-4">
        <Dropdown
          placeholder="Select"
          floating
          selection
          value={selectedOptions}
          onChange={handleContentViewChange}
          className="margin-2 influencer-options"
          options={finalizedFilters}
          multiple
          disabled={!records?.length || error}
        />
      </div>
      <div className="user-container flex flex flex-column margin-r-2">
        {campaignInfluencers.records?.length > 0 && !error && !fetching && (
          <Table
            basic="very"
            collapsing
            className={classNames([
              "influencer-table",
              { "influencer-table-cells": role === ROLES.BRAND },
            ])}
          >
            <Table.Header className="influencer-table-header">
              <Table.Row>
                {/* <Table.HeaderCell></Table.HeaderCell> */}
                <Table.HeaderCell className="fix-table-cell fix-table-cell-start">
                  Influencer Name
                </Table.HeaderCell>
                {!features.getInfluencerDetailFeature().budget_view &&
                  role !== ROLES.BRAND && (
                    <Table.HeaderCell>Budget</Table.HeaderCell>
                  )}
                {!features.getInfluencerDetailFeature().quote_view &&
                  role !== ROLES.BRAND && (
                    <Table.HeaderCell>Quote</Table.HeaderCell>
                  )}
                {/* {!features.getInfluencerDetailFeature().markup_view &&
                  role !== ROLES.BRAND && (
                    <Table.HeaderCell width={2}>Markup</Table.HeaderCell>
                  )} */}
                <Table.HeaderCell>Final Quote</Table.HeaderCell>
                <Table.HeaderCell>#Takes</Table.HeaderCell>
                {role === ROLES.HOB && (
                  <Table.HeaderCell>Chat</Table.HeaderCell>
                )}
                <Table.HeaderCell>Collaboration Status</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
                {selectedOptions.includes("viewInsight") && (
                  <Table.HeaderCell className="insights-bg">Insta Handle</Table.HeaderCell>
                )}
                {selectedOptions.includes("viewInsight") && (
                  <Table.HeaderCell className="insights-bg">Following</Table.HeaderCell>
                )}
                {selectedOptions.includes("viewInsight") && (
                  <Table.HeaderCell className="insights-bg">Media Count</Table.HeaderCell>
                )}
                {selectedOptions.includes("viewInsight") && (
                  <Table.HeaderCell className="insights-bg">Average Likes</Table.HeaderCell>
                )}
                {selectedOptions.includes("viewInsight") && (
                  <Table.HeaderCell className="insights-bg">Average Comments</Table.HeaderCell>
                )}
                   {selectedOptions.includes("viewInsight") && (
                  <Table.HeaderCell className="insights-bg">Average Views</Table.HeaderCell>
                )}
                {selectedOptions.includes("viewInsight") && (
                  <Table.HeaderCell className="insights-bg">Engagement Ratio</Table.HeaderCell>
                )}
                {selectedOptions.includes("viewInsight") && (
                  <Table.HeaderCell className="insights-bg">Genres</Table.HeaderCell>
                )}
                {selectedOptions.includes("viewInsight") && (
                  <Table.HeaderCell className="insights-bg">Audience Location</Table.HeaderCell>
                )}
                {selectedOptions.includes("viewInsight") && (
                  <Table.HeaderCell className="insights-bg">Audience Gender</Table.HeaderCell>
                )}
                {selectedOptions.includes("viewInsight") && (
                  <Table.HeaderCell className="insights-bg">Audience Age</Table.HeaderCell>
                )}
                {selectedOptions.includes("viewInsight") && (
                  <Table.HeaderCell className="insights-bg">Estimated Reach</Table.HeaderCell>
                )}
                {selectedOptions.includes("viewInsight") && (
                  <Table.HeaderCell className="insights-bg">Estimated Impressions</Table.HeaderCell>
                )}
                {selectedOptions.includes("viewContent") && (
                  <Table.HeaderCell className="content-bg">Content Url</Table.HeaderCell>
                )} {selectedOptions.includes("viewContent") && (
                  <Table.HeaderCell className="content-bg">Content Status</Table.HeaderCell>
                )}
                {selectedOptions.includes("viewContent") && (
                  <Table.HeaderCell className="content-bg">Go Live</Table.HeaderCell>
                )}
                {selectedOptions.includes("viewContent") && (
                  <Table.HeaderCell className="content-bg">Issue</Table.HeaderCell>
                )}
                {selectedOptions.includes("viewContent") &&
                  (campaignType === CampaignType.BARTER_UGC ||
                    campaignType === CampaignType.PAID_UGC) && (
                    <Table.HeaderCell className="content-bg">
                      UGC-Product to be Shipped
                    </Table.HeaderCell>
                  )}
                {selectedOptions.includes("viewLogistics") && (
                  <Table.HeaderCell className="logistics-bg">Shipment Status</Table.HeaderCell>
                )}
                {selectedOptions.includes("viewLogistics") && (
                  <Table.HeaderCell className="logistics-bg">Influencer Address</Table.HeaderCell>
                )}
                {selectedOptions.includes("viewPayment") && (
                  <Table.HeaderCell className="payment-bg">
                    Payment Status
                  </Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body className="influencer-table-body">
              {(records || []).map(renderTable)}
            </Table.Body>
          </Table>
        )}
        {error && <div className="flex flex-align-center flex-justify-center width-100 height-100 text-bold text-5">No influencer is finalised for this campaign!</div>}
        {/* {!error && !campaignInfluencers?.records?.length && (
          <div>{message}</div>
        )} */}
        {fetching && <Loading />}
      </div>
    </Fragment>
  );
}

export default FinalisedInfluencers;
