import { requestForToken } from "firebaseConfig";
import { ModalsTypes, modalStore } from "modalStore";
import {
  CAMPAIGN_COLLABORATION_STATUS,
  CampaignType,
  campaignVisibility,
  profileStatus,
} from "./Enums";

export const CONFIG = {
  REACT_APP_BASE_URL: (window as any).env?.REACT_APP_BASE_URL,
  REACT_APP_GOOGLE_MAPS_API_KEY: (window as any).env
    ?.REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_FB_ACCESS_TOKEN: (window as any).env?.REACT_APP_FB_ACCESS_TOKEN,
  REACT_APP_FIREBASE_KEY: (window as any).env?.REACT_APP_FIREBASE_KEY,
};

export function debounce(func: Function, delay: number) {
  let timer: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), delay);
  };
}

export function formatDate(date: string) {
  const formattedDate = new Date(date).toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  return formattedDate;
}

export const formatStatusString = (status: string) => {
  return status
    ?.replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b\w/g, (c) => c.toUpperCase());
};

export function transformOptionsToKeyValue(options: any[]) {
  return (options || []).map((option) => ({
    label: option.label || option.name,
    value: option.id || option.value,
    key: option.id || option.value,
  }));
}
export const findLabelById = (options: any, id: string) => {
  const option = options.find((option: any) => option.id === id);
  return option ? option.label : "Unknown";
};

export const getCity = (addressArray: any[]) => {
  const cityComponent = addressArray.find((component) =>
    component.types.includes("locality")
  );

  return cityComponent ? cityComponent.long_name : "";
};

export const getStreet = (addressArray: any[]) => {
  const streetTypes = [
    "sublocality_level_1",
    "sublocality_level_2",
    "sublocality",
  ];

  const streetComponents = addressArray.filter((component) =>
    streetTypes.includes(component.types[0])
  );

  const streetNames = streetComponents
    .map((component) => component.long_name)
    .join(", ");

  return streetNames || "";
};

export const getState = (addressArray: any[]) => {
  const stateTypes = [
    "administrative_area_level_1",
    // "administrative_area_level_2",
    // "administrative_area_level_3"
  ];

  const stateComponent = addressArray.find((component) =>
    stateTypes.includes(component.types[0])
  );

  return stateComponent ? stateComponent.long_name : "";
};

export const getPostalCode = (place: any[]) => {
  const postalCodeType = "postal_code";

  const postalCodeComponent = place.find((component) =>
    component.types.includes(postalCodeType)
  );

  return postalCodeComponent ? postalCodeComponent.long_name : "";
};

export const formatNumber = (num: number): string => {
  if (isNaN(num) || (num as any === "NaN")) {
    return "-";
  }
  if (num >= 1000000) {
    return Math.round(num / 1000000) + "M";
  } else if (num >= 1000) {
    return Math.round(num / 1000) + "K";
  } else {
    return Math.round(num).toString();
  }
};

export const requestNotificationPermission = (cb: (token: string) => void) => {
  // Request permission to send notifications
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");

      requestForToken(cb);
    } else {
      console.log("Unable to get permission to notify.");
    }
  });
};

export function getUserCategoryForCount(followersCount: any) {
  const getUserCategory = (count: number): string => {
    if (count < 10000) return "Nano";
    if (count >= 10000 && count < 100000) return "Micro";
    if (count >= 100000 && count < 1000000) return "Macro";
    if (count >= 1000000 && count < 10000000) return "Mega";
    return "Ace";
  };

  return getUserCategory(followersCount);
}

export function handleLogout() {
  modalStore.showModal(ModalsTypes.AlertModal, {
    message: "You have been logged out. Please login again to continue",
    title: "Logged Out!",
    primaryBtnTitle: "Ok",
    handleSuccessClick: () => {
      localStorage.clear();
      window.location.reload();
    },
  });
}

export function handleFacebookConfirmation() {
  modalStore.showModal(ModalsTypes.AlertModal, {
    message: "Please Give Confirmation for Accessing Your Information",
    title: "Confirmation!",
    primaryBtnTitle: "Ok",
    secondaryBtnTitle: "Cancel",
    handleCancelClick: () => {
      window.location.reload();
    },
  });
}

export function handleRemoveUser(message: string) {
  modalStore.showModal(ModalsTypes.AlertModal, {
    message: message,
    title: "Alert!",
    // primaryBtnTitle: "Ok",
    // secondaryBtnTitle: "Cancel",
    // handleSuccessClick: () => {
    //   localStorage.clear();
    //   window.location.reload();
    // },
  });
}

export const campaignFollowersOptions = [
  {
    label: "Ace",
    value: "ace",
  },
  {
    label: "Micro",
    value: "micro",
  },
  {
    label: "Macro",
    value: "macro",
  },
  {
    label: "Mega",
    value: "mega",
  },
  {
    label: "Nano",
    value: "nano",
  },
  // {
  //   label: "Custom",
  //   value: "custom",
  // },
];

export const GenderOptions = [
  { label: "Male", value: "MALE" },
  { label: "Female", value: "FEMALE" },
  { label: "Others", value: "OTHERS" },
];

export const NewGenderOptions = [
  { label: "Both", value: "BOTH" },
  { label: "Female", value: "FEMALE" },
  { label: "Male", value: "MALE" },
  { label: "Not Specified", value: "NOTSPECIFIED" },
];

export const emailNumberOptions = [
  { text: "All", value: "NONE", key: "All" },
  { text: "No", value: "NO", key: "No" },
  { text: "Yes", value: "YES", key: "Yes" },
];

export const registrationStatusOptions = [
  { text: "All", value: "NONE", key: "All" },
  { text: "Completed", value: profileStatus.COMPLETED, key: "Completed" },
  { text: "Invited", value: profileStatus.INVITED, key: "Invited" },
  { text: "Pending", value: profileStatus.PENDING, key: "Pending" },
];

export const followersOptions = [
  { value: "ace", text: "Ace (1M+)" },
  { value: "macro", text: "Macro (100K - 500K)" },
  { value: "mega", text: "Mega (500K - 1M)" },
  { value: "micro", text: "Micro (10K - 100K)" },
  { value: "nano", text: "Nano (1K - 10K)" },
  { value: "custom", text: "Custom" },
];

export const compaignTypeOptions = [
  {
    label: "Public Campaign",
    value: campaignVisibility.PUBLIC,
  },
  {
    label: "Private Campaign",
    value: campaignVisibility.PRIVATE,
  },
];

export const paymentTermOptions = [
  {
    label: "30",
    value: "30",
  },
  {
    label: "45",
    value: "45",
  },
  {
    label: "60",
    value: "60",
  },
  {
    label: "90",
    value: "90",
  },
];

export const radioOptions = [
  { label: "Event", value: "EVENT" },
  { label: "Location", value: "LOCATION" },
];

export const campaignOptions = [
  { label: "Barter Repost", value: CampaignType.BARTER_REPOST },
  { label: "Barter UGC", value: CampaignType.BARTER_UGC },
  { label: "Paid Repost", value: CampaignType.PAID_REPOST },
  { label: "Paid UGC", value: CampaignType.PAID_UGC },
];

export const changeCollaborationStatus = (collaborationStatus: string) => {
  switch (collaborationStatus) {
    case CAMPAIGN_COLLABORATION_STATUS.POST_TO_INFLUENCER:
      return "Pending with Influencer";
    case CAMPAIGN_COLLABORATION_STATUS.APPLY_CAMPAIGN:
      return "Accepted By Infulencer";
    case CAMPAIGN_COLLABORATION_STATUS.DECLINE_CAMPAIGN:
      return "Declined By Influencer";
    case CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_BRAND:
      return "Accepted By Brand";
    case CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_BRAND:
      return "Declined By Brand";
    case CAMPAIGN_COLLABORATION_STATUS.ACCEPT_BY_HOB:
      return "Accepted By Hob";
    case CAMPAIGN_COLLABORATION_STATUS.DECLINE_BY_HOB:
      return "Application Unsuccessful";
    case CAMPAIGN_COLLABORATION_STATUS.PENDING:
      return "Not Sent to Influencer";
    default:
      return "";
  }
};

export function handleTermsModal() {
  modalStore.showModal(ModalsTypes.DetailedTermModal , {
    message:"",
    title: "Terms of Service and Privacy Policy!" , 
  })
}

// const sortData = (data: any[], sortingCriteria: any) => {
//   const { sortBy, sortOrder } = sortingCriteria || {};
//   const sortedData = [...data];

//   sortedData.sort((a, b) => {
//     const valueA = a[sortBy];
//     const valueB = b[sortBy];

//     if (valueA < valueB) {
//       return sortOrder === "ascending" ? -1 : 1;
//     }
//     if (valueA > valueB) {
//       return sortOrder === "ascending" ? 1 : -1;
//     }
//     return 0;
//   });

//   return sortedData;
// };
