import { useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AuthLayout from "components/AuthLayout";
import SvgIcon, { CUSTOM_SVG_ICON, SVGType } from "components/SvgIcon";
import { FacebookAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, facebookProvider } from "firebaseConfig";
import { requestAuthenticationWithFacebook } from "store/actions";
import { getAuthState } from "store/selectors";
import { URLRoutes } from "URLRoutes";
import UsePrevious from "HOC/UsePrevious";
import Loading from "components/Loading";

function FacebookAuthentication() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isAuthenticated, user, message, fetching, error, emailVerified } =
    useSelector(getAuthState);

  const wasFetching = UsePrevious(fetching);

  const handleFacebookAuthentication = () => {
    facebookProvider.setCustomParameters({
      config_id: "1373830100684881",
      response_type: "code",
      display: "popup",
    });
    signInWithPopup(auth, facebookProvider)
      .then(async (result) => {
        try {
          const credential = FacebookAuthProvider.credentialFromResult(
            result
          ) as any;
          const token = credential.accessToken;
          const user = result.user;
          dispatch(
            requestAuthenticationWithFacebook({ inviteId: id, token: token })
          );
        } catch (error: any) {
        }
      })
      .catch((error) => {
        if (error.code === "auth/account-exists-with-different-credential") {
          const credentialError = FacebookAuthProvider.credentialFromError(error);
          const token = credentialError?.accessToken;
          if (token) {
            dispatch(requestAuthenticationWithFacebook({ inviteId: id, token: token }));
          }
        } else if (error.code === "auth/cancelled-popup-request") {
          toast.error("Popup closed by the user");
        }
        console.error("Error during Facebook login:", error);
      });
  };


  useEffect(() => {
    if (wasFetching && !fetching && !error) {
      if(emailVerified) {
        toast.success(message);
      } else {
        toast.info(message, {
          position: "top-right",
          autoClose: 30000,
          style: {
            position: "absolute",
            maxWidth: '500px', 
            right: 0,       
            margin: '0',   
          }
        });      }
    } else if (error) {
      toast.error(message);
    }
  }, [fetching, error, message]);
  
  useEffect(() => {
    if (emailVerified) {
      navigate(URLRoutes.clients.registration.replace(":id", id as string));
    }
  }, [emailVerified]);

  useEffect(() => {
    toast.dismiss();
  }, []);

  return (
    <AuthLayout
      image="/images/womanSunglasses.png"
      title="Discover the world’s top Influencers."
      titlePosition="top"
      contentClassName="flex-justify-center flex-align-center"
      bannerClassName="background-linear1"
      imagePosition="right"
    >
     {!fetching && <div className="width-45 padding-b-10">
        <img src="/images/logo.png" style={{ width: "100%", height: "100%" }} />
      </div> }
      {!fetching && <div
        className="signup-facebook margin-b-6"
        onClick={handleFacebookAuthentication}
      >
        <SvgIcon
          svgType={SVGType.CUSTOM}
          name={CUSTOM_SVG_ICON.facebook}
          size={"large"}
          baseclassname={"padding-r-3"}
        />
        Facebook Authentication
      </div>}
      {fetching && <Loading />}
    </AuthLayout>
  );
}

export default FacebookAuthentication;
